<script lang="ts">
  import {onMount} from "svelte";
  import Button from "../../components/Button.svelte";
  import CheckBox from "../../components/Checkbox.svelte";
  import Header from "../../components/Header.svelte";
  import {eligibility, step} from "../../stores/stores";

  let selected: string[] = [];

  onMount(async () => {
    eligibility.subscribe(value => (selected = value));
  });

  const handleSubmit = async () => {
    eligibility.set(selected);
    step.set(3);
  };
</script>

<div class="uc-h-full">
  <form class="uc-h-full" on:submit|preventDefault={handleSubmit}>
    <div class="uc-h-full uc-grid uc-grid-rows-[58px_1fr_90px]">
      <Header />
      <div class="uc-overflow-y-scroll">
        <div class="uc-px-5 mobile:uc-px-size-default-x-spacing">
          <div class="uc-mt-size-default-y-spacing">
            <p
              class="uc-text-body uc-font-medium uc-text-color-text-secondary uc-text-justify"
            >
              Just a few details about yourself to help us check your
              eligibility for this cover
            </p>
          </div>
          <p
            class="uc-mt-5 uc-text-body uc-text-color-text-primary uc-font-bold uc-leading-none"
          >
            Select all that apply to you
          </p>
        </div>
        <div class="uc-mt-5 uc-px-5 mobile:uc-px-size-default-x-spacing">
          <CheckBox
            id="1"
            name="ageStatus"
            value="ageStatus"
            checked={selected.includes("ageStatus")}
            bind:group={selected}
            label="I am between 18 to 65 years old"
          />
          <CheckBox
            id="2"
            name="residencyStatus"
            value="residencyStatus"
            checked={selected.includes("residencyStatus")}
            bind:group={selected}
            label="I am an Australian or New Zealand citizen or permanent resident"
          />
          <CheckBox
            id="3"
            name="vaccinationStatus"
            value="vaccinationStatus"
            checked={selected.includes("vaccinationStatus")}
            bind:group={selected}
            label="I am fully vaccinated against COVID-19"
          />
          <CheckBox
            id="4"
            name="diagnoseStatus"
            value="diagnoseStatus"
            checked={selected.includes("diagnoseStatus")}
            bind:group={selected}
            label="I am not currently diagnosed with COVID-19 or have any COVID-19 symptoms"
          />
        </div>
      </div>
      <div
        class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-py-5 uc-border-t-half uc-border-color-columbia-blue"
      >
        <Button disabled={selected.length !== 4} type="submit">Continue</Button>
      </div>
    </div>
  </form>
</div>
