<script lang="ts">
  import Info from "../assets/info.svelte";
  import {charges} from "../utils/constants";
  import {userDetails} from "../stores/stores";
  import type {State} from "../utils/constants";
  import {formatAmount} from "../utils/currencyFormatter";

  let state: State;

  userDetails.subscribe(val => (state = val.state || "NSW"));

  const [stateCharge] = charges.filter(x => x.states.includes(state));
</script>

<div class="uc-p-size-default-x-spacing">
  <h1
    class="uc-flex uc-items-center uc-text-body uc-text-left uc-font-extrabold uc-text-color-text-primary uc-mb-5"
  >
    Breakdown of Charges <span class="uc-ml-1">
      <span class="uc-group uc-relative uc-cursor-pointer">
        <Info />
        <span
          class="uc-absolute after:uc-absolute after:uc-top-0 after:uc-bg-color-antiflash-white uc-top-10 uc--left-36 uc-w-[265px] uc-invisible group-hover:uc-visible uc-z-20 uc-bg-color-antiflash-white uc-py-3 uc-px-size-tiny-x-spacing uc-text-color-text-secondary uc-font-normal uc-text-label uc-text-center uc-rounded-default "
        >
          Charges might vary based on state but total payable remains the same
        </span>
      </span>
    </span>
  </h1>
  <div
    class="uc-text-label uc-font-medium uc-text-color-text-secondary uc-text-left"
  >
    <div class="uc-border-b uc-border-color-text-header">
      {#each stateCharge.data as charge}
        <div class="uc-flex uc-justify-between uc-items-center uc-pb-5">
          <p>{charge.name}</p>
          <p class="uc-text-left uc-w-1/5">{formatAmount(charge.amount, 2)}</p>
        </div>
      {/each}
    </div>
    <div
      class="uc-flex uc-justify-between uc-items-center uc-font-bold uc-text-color-text-primary uc-mt-5"
    >
      <p>Total Payable</p>
      <p class="uc-text-left uc-w-1/5">
        {formatAmount(
          stateCharge.data.reduce((acc, curr) => curr.amount + acc, 0),
          2,
        )}
      </p>
    </div>
  </div>
</div>
