<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_851_10706)">
    <path
      d="M5.53346 14.5969C3.80013 14.5969 2.00013 14.1302 0.400127 13.1969C0.13346 13.0635 0.000126954 12.7302 0.0667936 12.4635C0.13346 12.1969 0.400127 11.9969 0.666794 11.9969C1.80013 12.0635 2.93346 11.7969 3.93346 11.3302C1.46679 9.79688 0.800127 7.66354 0.73346 5.99687C0.666794 4.06354 1.33346 2.46354 1.40013 2.39687C1.46679 2.19687 1.66679 1.99687 1.93346 1.99687C2.20013 1.99687 2.40013 2.06354 2.53346 2.26354C3.66679 3.86354 5.40013 4.86354 7.33346 4.99687C7.33346 3.93021 7.80013 2.93021 8.60013 2.19687C10.0001 0.996875 12.0668 0.996875 13.4668 2.19687C14.0001 1.99687 14.5335 1.73021 15.0001 1.39688C15.2001 1.26354 15.5335 1.26354 15.7335 1.39688C15.9335 1.53021 16.0668 1.86354 16.0001 2.13021C15.8001 3.06354 15.3335 3.93021 14.6668 4.66354C14.6668 4.79688 14.6668 4.86354 14.6668 4.99687C14.6668 8.66354 13.0668 11.7302 10.2001 13.3302C8.80013 14.1969 7.20013 14.5969 5.53346 14.5969ZM3.33346 12.9969C5.53346 13.5302 7.73346 13.2635 9.60013 12.1969C12.0001 10.7969 13.4001 8.13021 13.4001 4.99687C13.4001 4.86354 13.4001 4.73021 13.3335 4.59687C13.2668 4.39687 13.3335 4.13021 13.5335 3.99688C13.6668 3.86354 13.8001 3.66354 13.9335 3.53021C13.8001 3.59688 13.6001 3.66354 13.4668 3.66354C13.2001 3.73021 12.9335 3.66354 12.8001 3.46354C11.9335 2.53021 10.4668 2.39688 9.53346 3.26354C8.93346 3.66354 8.66679 4.33021 8.66679 4.99687V5.66354C8.66679 5.99687 8.40013 6.33021 8.00013 6.33021C5.80013 6.39687 3.73346 5.53021 2.26679 3.99688C1.86679 5.73021 1.73346 8.99687 5.60013 10.6635C5.80013 10.7969 6.00013 10.9969 6.00013 11.2635C6.00013 11.5302 5.93346 11.7302 5.73346 11.8635C4.93346 12.3969 4.13346 12.7969 3.33346 12.9969Z"
      fill="#545464"
    />
  </g>
  <defs>
    <clipPath id="clip0_851_10706">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</svg>
