<script lang="ts">
  export let id = "id";
  export let type = "text";
  export let name = "name";
  export let placeholder = "placholder";
  export let styleClass = "";
  export let value = "value";
  export let label = "label";
  export let errored: boolean | string = false;
  export let errorMessage = "enter valid details";
  export let disabled = false;

  export let handlePhoneChange: (e) => void;

  const validateInput = event => {
    const theEvent = event || window.event;
    let key;

    // Handle paste
    if (theEvent.type === "paste") {
      key = event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
</script>

<div
  class={`${styleClass} uc-flex uc-flex-col uc-h-button-height uc-border uc-border-solid ${
    errored ? "uc-border-color-secondary" : "uc-border-color-columbia-blue"
  } uc-rounded-md uc-w-full uc-py-2 ${disabled ? "uc-opacity-50" : ""}`}
>
  <div class="uc-relative">
    <input
      {type}
      on:input={handlePhoneChange}
      on:keypress={validateInput}
      class="uc-absolute uc-top-3.5 uc-left-11 uc-block uc-peer uc-w-2/3 uc-placeholder-transparent uc-appearance-none uc-text-color-text-primary uc-text-label uc-bg-transparent focus:uc-outline-none"
      {id}
      {name}
      {placeholder}
      {value}
      {disabled}
      minlength="11"
      maxlength="11"
    />
    <label
      class={`uc-absolute uc-top-0 uc-left-4 uc-text-footer2 uc-transition-all peer-focus:uc-top-0 peer-focus:uc-text-footer2
         ${
           errored
             ? "peer-focus:uc-left-4 uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-color-text-danger/80 peer-focus:uc-text-color-text-danger/80"
             : "peer-focus:uc-left-4 uc-text-color-text-primary/80 peer-placeholder-shown:uc-text-color-text-primary/80 peer-focus:uc-text-color-text-primary/80"
         } uc-uppercase`}
      for={id}>{errored ? errorMessage : label}</label
    >
    <p
      class="uc-absolute uc-top-3.5 uc-left-4 uc-block uc-placeholder-transparent uc-appearance-none uc-text-color-text-primary uc-text-label uc-bg-transparent focus:uc-outline-none"
    >
      +61
    </p>
  </div>
</div>
