export const formatPhone = value => {
  let newNumber = value.split("");
  if (newNumber[0] === "0") {
    newNumber.splice(0, 1);
  }
  if (newNumber.length > 2) {
    newNumber = newNumber.filter(x => !isNaN(parseInt(x)));
  }
  return newNumber
    .map((n, i) => {
      if (Number(i) % 3 === 2 && i != 8) {
        return `${n} `;
      }
      return n;
    })
    .join("");
};

export const formatCard = value => {
  let newNumber = value.split("");
  if (newNumber.length > 4) {
    newNumber = newNumber.filter(x => !isNaN(parseInt(x)));
  }
  return newNumber
    .map((n, i) => {
      if (Number(i) % 4 === 3 && i != 15) {
        return `${n} `;
      }
      return n;
    })
    .join("");
};

export const formatExpiry = value => {
  let newNumber = value.split("");
  if (newNumber.length >= 2) {
    newNumber = newNumber.filter(x => !isNaN(parseInt(x)));
    newNumber.splice(2, 0, "/");
  }

  return newNumber.join("");
};

export const validateInput = event => {
  const theEvent = event || window.event;
  let key;

  // Handle paste
  if (theEvent.type === "paste") {
    key = event.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};
