export default {
    amount: 122,
    decimal: 45,
};
export const charges = [
    {
        states: ["NSW", "QLD"],
        data: [
            {
                name: "Base Premium",
                amount: 100.08,
            },
            {
                name: "GST",
                amount: 10.01,
            },
            {
                name: "Stamp Duty",
                amount: 9.91,
            },
            {
                name: "Payment Fee",
                amount: 2.45,
            },
        ],
    },
    {
        states: ["ACT"],
        data: [
            {
                name: "Base Premium",
                amount: 109.09,
            },
            {
                name: "GST",
                amount: 10.91,
            },
            {
                name: "Stamp Duty",
                amount: 0.0,
            },
            {
                name: "Payment Fee",
                amount: 2.45,
            },
        ],
    },
    {
        states: ["SA"],
        data: [
            {
                name: "Base Premium",
                amount: 98.28,
            },
            {
                name: "GST",
                amount: 9.83,
            },
            {
                name: "Stamp Duty",
                amount: 11.89,
            },
            {
                name: "Payment Fee",
                amount: 2.45,
            },
        ],
    },
    {
        states: ["VIC", "TAS", "WA", "NT"],
        data: [
            {
                name: "Base Premium",
                amount: 99.17,
            },
            {
                name: "GST",
                amount: 9.92,
            },
            {
                name: "Stamp Duty",
                amount: 10.91,
            },
            {
                name: "Payment Fee",
                amount: 2.45,
            },
        ],
    },
];
const { env } = appENV;
export const ENV = env.ENV;
export const APP_BASE_URL = env.APP_BASE_URL;
export const API_BASE_URL = env.API_BASE_URL;
