<script lang="ts">
  import {getContext} from "svelte";
  import {TABS} from "./Tabs.svelte";

  const tab = {};
  const {registerTab, selectTab, selectedTab} = getContext(TABS);

  registerTab(tab);

  export let style: string;

  $: selected = $selectedTab === tab;
</script>

<button
  class={`${style} uc-h-10 uc-w-1/2 ${
    selected
      ? "uc-bg-color-primary uc-text-white"
      : "uc-bg-white text-color-text-primary"
  }  uc-px-size-tiny-x-spacing uc-text-label uc-uppercase uc-font-medium uc-border-solid uc-border-y uc-border-color-primary`}
  on:click={() => selectTab(tab)}
>
  <slot /></button
>
