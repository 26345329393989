<script lang="ts">
  import {onMount} from "svelte";
  import {APP_BASE_URL} from "../utils/constants";
  import Stepper from "./Stepper.svelte";
  import type {UserI} from "../interfaces/User";
  import SvgComponent from "./SVGComponent.svelte";
  import ChevronLeft from "../assets/chevron-left.svelte";
  import HasCover from "../ModalPages/HasCover.svelte";
  import {
    eligibility,
    checked,
    userDetails,
    component,
    showModal,
    showCross,
    step,
    completed,
  } from "../stores/stores";

  let pageStep: number;

  step.subscribe(val => (pageStep = val));

  const toggleModal = (modalComponent, showModalCross = true) => {
    component.set(modalComponent);
    showModal.set(true);
    showCross.set(showModalCross);
  };

  const stepArray = ["Personal Details", "Policy", "Complete"];

  let user: UserI;
  let isChecked: boolean;
  let eligibilityStatus: string[];

  eligibility.subscribe(value => (eligibilityStatus = value));
  checked.subscribe(value => (isChecked = value));
  userDetails.subscribe(value => (user = value));

  const checkForm = () => {
    if ($completed) {
      completed.set(false);
      eligibility.set([]);
      checked.set(false);
      userDetails.set({
        firstName: "",
        lastName: "",
        mobileNumber: "",
        dateOfBirth: "",
        email: "",
        postcode: "",
        state: "",
        suburb: "",
        locality: "",
      });
      step.set(1);
      return;
    }
    if (pageStep === 1) return;

    if (pageStep >= 2 && eligibilityStatus.length != 4) {
      step.set(2);
      return;
    }

    if (pageStep >= 3 && !isChecked) {
      step.set(3);
      return;
    }

    if (
      pageStep >= 4 &&
      Object.values(user).every(x => x === null || x === "")
    ) {
      step.set(4);
      return;
    }
  };

  onMount(async () => {
    checkForm();
  });

  const backWards = () => {
    if (pageStep >= 2 && pageStep <= 4) {
      step.update(n => n - 1);
    }
  };
</script>

{#if pageStep === 1}
  <div
    class="uc-flex uc-justify-between uc-items-center uc-size-header-height uc-border-b-half uc-border-color-columbia-blue uc-px-5 mobile:uc-px-size-default-x-spacing uc-py-4 mobile:uc-py-3.5"
  >
    <div>
      <p
        class="uc-text-color-text-header uc-font-light uc-text-footer3 uc-mb-1.5"
      >
        Powered by
      </p>
      <img
        class="uc-h-4 uc-w-size-header-LogoWidth"
        src={`${APP_BASE_URL}/assets/upcover.png`}
        alt="upcover"
      />
    </div>
    <div class="uc-font-light uc-text-right uc-text-footer">
      <p class="uc-text-color-text-header">Already have cover?</p>
      <button
        class="uc-text-color-text-danger uc-underline"
        on:click={() => toggleModal(HasCover, true)}
      >
        click here
      </button>
    </div>
  </div>
{:else}
  <div
    class="uc-flex uc-justify-between uc-items-center uc-size-header-height uc-border-b-half uc-border-color-columbia-blue uc-px-5 mobile:uc-px-size-default-x-spacing uc-py-4 mobile:uc-py-3.5"
  >
    {#if pageStep !== 5}
      <SvgComponent Component={ChevronLeft} onClickAction={() => backWards()} />
    {:else}
      <div />
    {/if}

    <div class="uc-absolute uc-left-1/2 uc--translate-x-1/2 uc-w-1/4">
      <Stepper steps={stepArray} currentStepNumber={pageStep - 1} />
    </div>
    <div>
      <p
        class="uc-text-color-text-header uc-font-light uc-text-footer3 uc-mb-1.5"
      >
        Powered by
      </p>
      <img
        class="uc-h-4 uc-w-size-header-LogoWidth"
        src={`${APP_BASE_URL}/assets/upcover.png`}
        alt="upcover"
      />
    </div>
  </div>
{/if}
