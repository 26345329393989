import {ENV} from "./constants";

let factSheet = "";

let policyWording = "";

let targetMarketDetermination = "";

switch (ENV) {
  case "Production":
    factSheet =
      "https://firebasestorage.googleapis.com/v0/b/prod-upcover.appspot.com/o/covidCarePublicDocs%2F%20FactSheet.pdf?alt=media&token=4416ee0f-9333-4735-b0d4-f99ca23e1670";

    policyWording =
      "https://firebasestorage.googleapis.com/v0/b/prod-upcover.appspot.com/o/covidCarePublicDocs%2FPolicyWording.pdf?alt=media&token=853ad429-c3d6-42e6-93a0-282f27749f7d";

    targetMarketDetermination =
      "https://firebasestorage.googleapis.com/v0/b/prod-upcover.appspot.com/o/covidCarePublicDocs%2FTargetMarketDetermination.pdf?alt=media&token=8ebaf49e-9a80-467f-93e1-98ed9ef61e98";
    break;
  case "Development":
    factSheet =
      "https://firebasestorage.googleapis.com/v0/b/dev-v2-303006.appspot.com/o/covidCarePublicDocs%2F%20FactSheet.pdf?alt=media&token=ee5220a1-72d1-4f1c-a8d5-08df65828567";

    policyWording =
      "https://www.prorisk.com.au/siteassets/documents/prorisk-financial-services-guide-2021.pdf";

    targetMarketDetermination =
      "https://firebasestorage.googleapis.com/v0/b/dev-v2-303006.appspot.com/o/covidCarePublicDocs%2FTargetMarketDetermination.pdf?alt=media&token=699bbca5-1539-4e2a-802f-902de12a4f65";

    break;
  case "Staging":
    factSheet =
      "https://firebasestorage.googleapis.com/v0/b/staging-upcover.appspot.com/o/covidCarePublicDocs%2F%20FactSheet.pdf?alt=media&token=19207cfa-7c95-4869-872f-7f5e3bf193bb";

    policyWording =
      "https://firebasestorage.googleapis.com/v0/b/staging-upcover.appspot.com/o/covidCarePublicDocs%2FPolicyWording.pdf?alt=media&token=5bdf92a6-f1b4-48ab-aea2-1d76b9273b1c";

    targetMarketDetermination =
      "https://firebasestorage.googleapis.com/v0/b/staging-upcover.appspot.com/o/covidCarePublicDocs%2FTargetMarketDetermination.pdf?alt=media&token=098625da-61c1-4a9b-935e-696720c59cd2";
    break;
  case "Demo":
    factSheet =
      "https://firebasestorage.googleapis.com/v0/b/dwidget-upc.appspot.com/o/covidCarePublicDocs%2F%20FactSheet.pdf?alt=media&token=641be3e9-4c0d-48c7-94dd-3cadf79b600a";

    policyWording =
      "https://firebasestorage.googleapis.com/v0/b/dwidget-upc.appspot.com/o/covidCarePublicDocs%2FPolicyWording.pdf?alt=media&token=c849b774-56ee-4bf0-bd91-baddf6bde784";

    targetMarketDetermination =
      "https://firebasestorage.googleapis.com/v0/b/dwidget-upc.appspot.com/o/covidCarePublicDocs%2FTargetMarketDetermination.pdf?alt=media&token=d380c748-03de-452e-92de-4c506b8ab2c6";
    break;
  default:
    factSheet =
      "https://firebasestorage.googleapis.com/v0/b/dev-v2-303006.appspot.com/o/covidCarePublicDocs%2F%20FactSheet.pdf?alt=media&token=ee5220a1-72d1-4f1c-a8d5-08df65828567";

    policyWording =
      "https://www.prorisk.com.au/siteassets/documents/prorisk-financial-services-guide-2021.pdf";

    targetMarketDetermination =
      "https://firebasestorage.googleapis.com/v0/b/dev-v2-303006.appspot.com/o/covidCarePublicDocs%2FTargetMarketDetermination.pdf?alt=media&token=699bbca5-1539-4e2a-802f-902de12a4f65";
}

export default {
  links: {
    "Product Disclosure Statement": {
      title: "Product Disclosure Statement",
      href: policyWording,
    },
    "Financial Service Guide": {
      title: "Financial Service Guide",
      href: policyWording,
    },
    "Target Market Determination": {
      title: "Target Market Determination",
      href: targetMarketDetermination,
    },
    "Fact Sheet": {
      title: "Fact Sheet",
      href: factSheet,
    },
    "Cancel Policy": {
      href: "mailto:support@upcover.com?subject=Cancel COVID BounceBack Cover",
    },
    "Make Claim": {
      href: "https://upcover.typeform.com/to/ZX0TM8Xu",
    },
    twitter: {
      href: "https://www.twitter.com/getupcover",
    },
    facebook: {
      href: "https://www.facebook.com/getupcover/",
    },
    instagram: {
      href: "https://www.instagram.com/get_upcover/",
    },
    linkedin: {
      href: "https://www.linkedin.com/company/upcover",
    },
  },
};
