<script lang="ts">
  import {APP_BASE_URL} from "../utils/constants";
  import {validateInput} from "../utils/formatter";
  interface CardType {
    cvvSize: number;
    type: string;
  }

  export let cardType: CardType;
  export let cardNumber: string;
  export let expiry: string;
  export let cvc: string;

  export let cardNumberChange: (e) => void;
  export let cardExpiryChange: (e) => void;
  export let cardCvcChange: (e) => void;

  $: imageSrc = "card.png";

  $: {
    switch (cardType.type) {
      case "visa":
        imageSrc = "visa-small.png";
        break;
      case "mastercard":
        imageSrc = "mastercard-small.png";
        break;
      case "american-express":
        imageSrc = "amex-small.png";
        break;

      default:
        imageSrc = "card.png";
    }
  }
  export let errors = {
    cardNumber: "",
    expiry: "",
    cvc: "",
  };

  const onKeyUp = (e, nextID) => {
    if (
      e.target.value.length === parseInt(e.target.attributes["maxlength"].value)
    ) {
      e.target.nextSibling.parentNode.childNodes[0].focus();
      const nextSibling = document.getElementById(nextID);
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };
</script>

<div
  class={`uc-flex uc-flex-col uc-h-button-height uc-border uc-border-solid ${
    errors.cardNumber || errors.expiry || errors.cvc
      ? "uc-border-color-secondary"
      : "uc-border-columbia-blue"
  } uc-rounded-md uc-w-full uc-pt-2 uc-pb-3 uc-px-4 `}
>
  <fieldset>
    <legend
      class={`uc-text-footer2 ${
        errors.cardNumber || errors.expiry || errors.cvc
          ? "uc-text-color-text-danger/80"
          : " uc-text-color-text-primary/80"
      } uc-uppercase`}
      for="creditCardInput">card details</legend
    >
    <div class="uc-flex uc-items-center">
      <div class="uc-basis-1/12">
        <img
          class="uc-h-4 uc-w-5 uc-mr-5"
          src={`${APP_BASE_URL}/assets/${imageSrc}`}
          alt="card"
        />
      </div>
      <div class="uc-relative uc-basis-1/2">
        <input
          id="cardNumber"
          type="text"
          on:input={cardNumberChange}
          on:keypress={e => validateInput(e)}
          on:keyup={e => onKeyUp(e, "creditExpiryNumber")}
          class="uc-block uc-w-full uc-appearance-none uc-text-color-text-primary uc-text-label uc-bg-transparent focus:uc-outline-none"
          name="cardNumber"
          minlength="12"
          maxlength="23"
          placeholder="Card Number"
          value={cardNumber}
        />
        {#if errors.cardNumber}
          <small
            class={`uc-absolute uc--bottom-9 uc-text-footer2 uc-transition-all peer-placeholder-shown:uc-top-2 peer-focus:uc-top-0 peer-focus:uc-text-footer2
         uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-color-text-danger/80 peer-focus:uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-footer2 uc-uppercase`}
            >{errors.cardNumber}</small
          >
        {/if}
      </div>
      <div class="uc-relative uc-basis-1/4">
        <input
          id="creditExpiryNumber"
          type="text"
          on:input={cardExpiryChange}
          on:keypress={e => validateInput(e)}
          on:keyup={e => onKeyUp(e, "creditCvvNumber")}
          class="uc-block uc-w-full uc-appearance-none uc-text-color-text-primary uc-text-label uc-bg-transparent focus:uc-outline-none"
          name="expiry"
          minlength="7"
          maxlength="7"
          placeholder="MM/YYYY"
          value={expiry}
        />
        {#if errors.expiry}
          <small
            class={`uc-absolute uc--bottom-9 uc-text-footer2 uc-transition-all peer-placeholder-shown:uc-top-2 peer-focus:uc-top-0 peer-focus:uc-text-footer2
         uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-color-text-danger/80 peer-focus:uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-footer2 uc-uppercase`}
            >{errors.expiry}</small
          >
        {/if}
      </div>
      <div class="uc-relative uc-basis-1/6">
        <input
          id="creditCvvNumber"
          type="text"
          on:input={cardCvcChange}
          on:keypress={e => validateInput(e)}
          class="uc-block uc-w-full uc-appearance-none uc-text-color-text-primary uc-text-label uc-bg-transparent focus:uc-outline-none"
          name="cvc"
          minlength="3"
          maxlength="4"
          placeholder="CVC"
          value={cvc}
        />
        {#if errors.cvc}
          <small
            class={`uc-absolute uc--bottom-9 uc-text-footer2 uc-transition-all peer-placeholder-shown:uc-top-2 peer-focus:uc-top-0 peer-focus:uc-text-footer2
         uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-color-text-danger/80 peer-focus:uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-footer2 uc-uppercase`}
            >{errors.cvc}</small
          >
        {/if}
      </div>
    </div>
  </fieldset>
</div>
