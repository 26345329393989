<script lang="ts">
  import Data from "../utils/links";
  import Twitter from "../assets/twitter.svelte";
  import SvgComponent from "./SVGComponent.svelte";
  import Linkedin from "../assets/linkedin.svelte";
  import Facebook from "../assets/facebook.svelte";
  import Instagram from "../assets/instagram.svelte";
  import UpcoverMiniLogo from "../assets/upcover-mini-logo.svelte";
  const year = new Date().getFullYear();
</script>

<div class="uc-p-size-default-x-spacing uc-bg-color-ghost-white">
  <div
    class="uc-flex uc-items-center uc-justify-between uc-pb-size-default-x-spacing"
  >
    <div class="uc-flex uc-items-center uc-justify-between uc-w-1/4">
      <SvgComponent Component={UpcoverMiniLogo} />
      <h1 class="uc-ml-size-tiny-x-spacing uc-text-body uc-font-bold">
        Upcover
      </h1>
    </div>
    <div class="uc-flex uc-items-center uc-justify-between uc-w-1/4">
      <a href={Data.links.instagram.href} target="_blank">
        <SvgComponent Component={Instagram} />
      </a>
      <a href={Data.links.twitter.href} target="_blank"
        ><SvgComponent Component={Twitter} /></a
      >
      <a href={Data.links.facebook.href} target="_blank"
        ><SvgComponent Component={Facebook} /></a
      >
      <a href={Data.links.linkedin.href} target="_blank"
        ><SvgComponent Component={Linkedin} /></a
      >
    </div>
  </div>
  <p
    class="uc-text-footer uc-font-normal uc-text-color-text-secondary uc-mb-size-default-y-spacing uc-text-justify"
  >
    Upcover Pty Ltd ABN 17 628 197 437 (“upcover”) is a Corporate Authorised
    Distributor and has entered into an arrangement with Armada Underwriting Pty
    Ltd ABN 84 000 989 131 AFSL 237402 (“Armada”). upcover markets insurance
    products and services on behalf of Armada. Cover is subject to the terms,
    conditions, limits and exclusions of the Policy.
  </p>
  <p
    class="uc-text-footer uc-font-normal uc-text-color-text-secondary uc-mb-size-default-y-spacing uc-text-justify"
  >
    All insurance is underwritten by Professional Risk Underwriting Pty Ltd ABN
    80 103 953 073 AFSL 308076 (“ProRisk”) On behalf of Certain Underwriters at
    Lloyd’s. Armada, ProRisk and upcover and their employees act as agents of of
    the insurers and not as your agent. Neither ProRisk, Armada or upcover act
    on your behalf when distributing the insurance products and services and any
    advice provided is general advice only and does not consider your
    objectives, financial situation or needs.
  </p>
  <p
    class="uc-text-footer uc-font-normal uc-text-color-text-secondary uc-mb-size-default-y-spacing uc-text-justify"
  >
    Information provided is general advice only and has been prepared without
    taking into account your particular objectives, financial situation or
    needs.
  </p>
  <p
    class="uc-text-footer uc-font-normal uc-text-color-text-secondary uc-mb-size-default-y-spacing uc-text-justify"
  >
    Read the relevant <a
      href={Data.links["Product Disclosure Statement"].href}
      target="_blank"
      class="uc-text-color-text-danger"
      >{Data.links["Product Disclosure Statement"].title}</a
    >
    or
    <a
      href={Data.links["Target Market Determination"].href}
      target="_blank"
      class="uc-text-color-text-danger"
      >{Data.links["Target Market Determination"].title}</a
    > available at upcover and consider whether it's appropriate before making any
    decisions about whether to buy a product.
  </p>
  <footer class="uc-text-footer uc-font-normal uc-text-color-text-secondary">
    &copy; Copyright {year} Upcover Pty Ltd ABN 17 628 197 437
  </footer>
</div>
