<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="9.94225" cy="9.9088" r="7.786" fill="#CDD2DF" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.4839 6.81622C13.7037 7.05666 13.687 7.42976 13.4465 7.64955L7.3515 13.2212C7.11106 13.441 6.73796 13.4242 6.51817 13.1838C6.29837 12.9434 6.31511 12.5703 6.55555 12.3505L12.6506 6.77883C12.891 6.55904 13.2641 6.57578 13.4839 6.81622Z"
    fill="white"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.81659 6.51711C7.05704 6.29732 7.43013 6.31406 7.64993 6.5545L13.2216 12.6495C13.4414 12.89 13.4246 13.2631 13.1842 13.4829C12.9437 13.7027 12.5706 13.6859 12.3508 13.4455L6.77921 7.35045C6.55941 7.11001 6.57615 6.73691 6.81659 6.51711Z"
    fill="white"
  />
</svg>
