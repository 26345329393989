<svg
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="26" height="26" rx="5" fill="#F6F6FA" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.9993 7.16667C11.7617 7.16667 10.5747 7.65833 9.69952 8.5335C8.82435 9.40867 8.33268 10.5957 8.33268 11.8333C8.33268 13.6405 9.50853 15.3941 10.7978 16.7551C11.4305 17.4229 12.0652 17.9697 12.5424 18.3497C12.72 18.4911 12.8751 18.6089 12.9993 18.7007C13.1236 18.6089 13.2787 18.4911 13.4563 18.3497C13.9335 17.9697 14.5682 17.4229 15.2009 16.7551C16.4902 15.3941 17.666 13.6405 17.666 11.8333C17.666 10.5957 17.1744 9.40867 16.2992 8.5335C15.424 7.65833 14.237 7.16667 12.9993 7.16667ZM12.9993 19.4167C12.6758 19.902 12.6756 19.9019 12.6755 19.9018L12.6738 19.9007L12.6699 19.8981L12.6565 19.8891C12.6452 19.8813 12.629 19.8703 12.6083 19.8559C12.5668 19.8272 12.5073 19.7854 12.4322 19.7312C12.2822 19.6228 12.0697 19.4646 11.8157 19.2623C11.3085 18.8584 10.6307 18.275 9.95088 17.5574C8.61517 16.1475 7.16602 14.1095 7.16602 11.8333C7.16602 10.2862 7.7806 8.80251 8.87456 7.70854C9.96852 6.61458 11.4523 6 12.9993 6C14.5464 6 16.0302 6.61458 17.1241 7.70854C18.2181 8.80251 18.8327 10.2862 18.8327 11.8333C18.8327 14.1095 17.3835 16.1475 16.0478 17.5574C15.368 18.275 14.6902 18.8584 14.183 19.2623C13.929 19.4646 13.7165 19.6228 13.5665 19.7312C13.4914 19.7854 13.4319 19.8272 13.3904 19.8559C13.3697 19.8703 13.3535 19.8813 13.3422 19.8891L13.3288 19.8981L13.3249 19.9007L13.3237 19.9015C13.3235 19.9016 13.3229 19.902 12.9993 19.4167ZM12.9993 19.4167L13.3229 19.902C13.127 20.0327 12.8714 20.0324 12.6755 19.9018L12.9993 19.4167Z"
    fill="#252A41"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.9993 10.6667C12.355 10.6667 11.8327 11.189 11.8327 11.8333C11.8327 12.4777 12.355 13 12.9993 13C13.6437 13 14.166 12.4777 14.166 11.8333C14.166 11.189 13.6437 10.6667 12.9993 10.6667ZM10.666 11.8333C10.666 10.5447 11.7107 9.5 12.9993 9.5C14.288 9.5 15.3327 10.5447 15.3327 11.8333C15.3327 13.122 14.288 14.1667 12.9993 14.1667C11.7107 14.1667 10.666 13.122 10.666 11.8333Z"
    fill="#252A41"
  />
</svg>
