<script lang="ts">
  import {onMount} from "svelte";
  import Data from "../../utils/links";
  import price from "../../utils/constants";
  import Duty from "../../ModalPages/Duty.svelte";
  import {APP_BASE_URL} from "../../utils/constants";
  import Header from "../../components/Header.svelte";
  import Button from "../../components/Button.svelte";
  import {formatAmount} from "../../utils/currencyFormatter";
  import {component, showModal, showCross, step} from "../../stores/stores";

  const toggleModal = (modalComponent, showModalCross = true) => {
    component.set(modalComponent);
    showModal.set(true);
    showCross.set(showModalCross);
  };

  const pros = [
    "One off, $5,000 payment, directly to you",
    "Get a quote and buy in 1 minute",
    "Submit claims 100% online, 24/7 ",
    "Underwritten by certain underwriters at Lloyd’s of London ",
  ];

  onMount(async () => {
    await window.analytics.track("Home", {
      plan: "Covid BounceBack",
    });
  });

  const checkEligibility = async () => {
    await window.analytics.track("eligibility checked", {
      plan: "Covid BounceBack",
    });

    step.set(2);
  };
</script>

<div
  class="uc-h-full uc-grid uc-grid-rows-[58px_1fr_130px] mobile:uc-grid-rows-[58px_1fr_121px]"
>
  <Header />
  <div class="uc-overflow-y-scroll">
    <div class="uc-px-5 mobile:uc-px-size-default-x-spacing">
      <h1
        class="uc-mt-size-default-y-spacing uc-text-[22px] uc-font-black uc-text-color-text-primary uc-leading-none"
      >
        COVID Bounceback insurance
      </h1>
      <p
        class="uc-mt-size-default-y-spacing uc-text-body uc-font-medium uc-text-color-text-secondary uc-text-justify"
      >
        Cover for COVID-19 if you are hospitalised for more than 24 hours
        despite being fully vaccinated.
      </p>
      <div class="uc-flex uc-items-center uc-my-5">
        <p
          class="uc-text-amount uc-text-color-text-primary uc-font-black uc-leading-none"
        >
          {formatAmount(price.amount, 0)}
          <sup
            class="uc-font-semibold uc-text-superscript uc--top-5 uc--left-2.5"
            >.{price.decimal}</sup
          >
        </p>
        <p class="uc-text-body uc-text-color-text-secondary">per year</p>
      </div>
    </div>
    <ul class="uc-mt-2 uc-px-5 mobile:uc-px-size-default-x-spacing">
      {#each pros as pro}
        <li class="uc-flex">
          <img
            src={`${APP_BASE_URL}/assets/tick.png`}
            class="uc-h-4 uc-w-4 uc-mt-3"
            alt="tick"
          />
          <p
            class="uc-text-body uc-font-normal uc-text-color-text-secondary uc-ml-size-tiny-x-spacing uc-my-2 uc-leading-normal"
          >
            {pro}
          </p>
        </li>
      {/each}
    </ul>
  </div>
  <div
    class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-py-size-tiny-x-spacing uc-border-t-half uc-border-color-columbia-blue"
  >
    <Button buttonAction={checkEligibility}>Check Eligibility</Button>
    <div class="uc-mt-1.5 uc-text-justify">
      <p class="uc-text-footer uc-text-color-text-primary">
        By continuing, you acknowledge you have read and understood your
        <span
          class="uc-text-footer uc-text-color-text-danger uc-cursor-pointer"
          on:click={() => toggleModal(Duty, true)}
        >
          Duty not to Misrepresent
        </span>
        &
        <a
          href={Data.links["Product Disclosure Statement"].href}
          target="_blank"
        >
          <span
            class="uc-text-footer uc-text-color-text-danger uc-cursor-pointer"
          >
            {Data.links["Product Disclosure Statement"].title}
          </span>
        </a>
      </p>
    </div>
  </div>
</div>
