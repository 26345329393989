<div class="uc-p-size-default-x-spacing">
  <h1
    class="uc-text-body uc-text-center uc-font-extrabold uc-text-color-text-primary uc-mb-3"
  >
    Duty to not Misrepresent
  </h1>
  <p class="uc-text-footer uc-font-light uc-text-color-text-secondary">
    You have a duty to take reasonable care not to make a misrepresentation. Our
    decision to insure You relies on the accuracy of the information You provide
    to Us. You must answer all the questions We ask You honestly, truthfully and
    accurately and in doing so You to take reasonable care not to make a
    misrepresentation (“Your Duty to take Reasonable Care”). If You do not
    answer Our questions in this way We may reduce or refuse to pay a Claim, or
    cancel the Policy. If You answer Our questions fraudulently, We may refuse
    to pay a Claim and treat the Policy as never having existed.
  </p>
</div>
