<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.00002 1.33329C4.33335 1.33329 1.33335 4.33329 1.33335 7.99996C1.33335 11.6666 4.33335 14.6666 8.00002 14.6666C11.6667 14.6666 14.6667 11.6666 14.6667 7.99996C14.6667 4.33329 11.6667 1.33329 8.00002 1.33329Z"
    stroke="#7988A9"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 10.6666V7.33329"
    stroke="#7988A9"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.00366 5.33337H7.99767"
    stroke="#7988A9"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
