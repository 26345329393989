<script lang="ts">
  export let type = "button";
  export let isLoading = false;
  export let disabled = false;
  export let buttonAction: () => void = () => console.log("clicked");
</script>

<button
  class={`uc-bg-color-primary uc-text-white uc-h-button-height uc-rounded-md uc-w-full uc-text-label uc-font-medium ${
    disabled || isLoading ? "uc-bg-color-columbia-blue" : ""
  }`}
  {type}
  disabled={disabled || isLoading}
  on:click={buttonAction}
>
  {#if isLoading}
    Processing...
  {:else}
    <slot />
  {/if}
</button>
