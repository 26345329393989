<script lang="ts">
  import {onMount} from "svelte";
  import Data from "../../utils/links";
  import {format, add} from "date-fns";
  import price from "../../utils/constants";
  import Duty from "../../ModalPages/Duty.svelte";
  import type {UserI} from "../../interfaces/User";
  import {APP_BASE_URL} from "../../utils/constants";
  import Button from "../../components/Button.svelte";
  import Header from "../../components/Header.svelte";
  import Breakdown from "../../ModalPages/Breakdown.svelte";
  import {formatAmount} from "../../utils/currencyFormatter";
  import ContactDetails from "../../ModalPages/ContactDetails.svelte";
  import {Tabs, TabList, TabPanel, Tab} from "../../components/Tabs/tabs";
  import {
    component,
    showModal,
    showCross,
    userDetails,
    showLoader,
    loaderSrc,
    checked,
    step,
  } from "../../stores/stores";

  const startDate = new Date();

  const endDate = add(startDate, {days: 364});

  let isChecked: boolean;
  let user: UserI;

  const toggleModal = (modalComponent, showModalCross: boolean) => {
    component.set(modalComponent);
    showModal.set(true);
    showCross.set(showModalCross);
  };

  $: loading = false;
  $: loaderSRC = "1st stage B&W.gif";

  onMount(async () => {
    showLoader.subscribe(value => {
      loading = value;
    });

    loaderSrc.subscribe(value => {
      loaderSRC = value;
    });

    userDetails.subscribe(value => {
      user = value;
    });

    checked.subscribe(value => {
      isChecked = value;
    });
  });

  onMount(async () => {
    showLoader.set(true);

    setTimeout(() => {
      loaderSrc.set("2nd stage B&W.gif");
      setTimeout(() => {
        showLoader.set(false);
        loaderSrc.set("1st stage B&W.gif");

        if (user.firstName === "") {
          toggleModal(ContactDetails, false);
        }
      }, 1000);
    }, 4000);

    await window.analytics.track("Product Details", {
      plan: "Covid BounceBack",
    });
  });

  const coverIncludes = [
    "If you contract COVID-19 and are hospitalised for 24 hours or more",
    "Where you contract COVID-19 anywhere in Australia",
    "Contracting COVID-19 from anywhere in the world",
    "A maximum of two (2) valid Covid BounceBack payments per policy year ",
  ];

  const coverExcludes = [
    "Are not fully vaccinated against the COVID-19 virus",
    "Are diagnosed with COVID-19 at the time of taking out the policy",
    "Are waiting results of a COVID-19 test at the time of taking out the policy, or had any close contacts including family members waiting on a COVID-19 test result",
    "Are hospitalised as a result of contracting COVID-19 for less than 24 hours",
  ];

  const handleClick = async () => {
    checked.set(isChecked);
    await window.analytics.track("Details Confirmed", {
      plan: "Covid BounceBack",
    });
    step.set(4);
  };
</script>

{#if loading}
  <Header />
  <div
    class="uc-overflow-y-scroll uc-flex uc-items-center uc-justify-center uc-z-20 uc-mt-10"
  >
    <div>
      <img
        src={`${APP_BASE_URL}/assets/${loaderSRC}`}
        height="234"
        width="220"
        alt="loader"
      />
      <p
        class="uc-text-loader uc-font-bold uc-text-color-text-primary uc-mt-10"
      >
        Checking your eligibility...
      </p>
    </div>
  </div>
{:else}
  <div class="uc-h-full uc-grid uc-grid-rows-[58px_1fr_90px]">
    <Header />
    <div class="uc-overflow-y-scroll">
      <div
        class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-mb-size-default-x-spacing uc-text-color-text-primary"
      >
        <div class="uc-mt-size-default-y-spacing">
          <p
            class="uc-text-body uc-font-semibold uc-text-color-text-secondary uc-text-justify"
          >
            Congratulations! You are eligible for COVID BounceBack insurance
          </p>
        </div>
        <div class="uc-flex uc-items-center">
          <p class="uc-text-amount uc-text-color-text-primary uc-font-black">
            {formatAmount(price.amount, 0)}
            <sup
              class="uc-font-semibold uc-text-superscript uc--top-5 uc--left-2.5"
              >.{price.decimal}</sup
            >
          </p>
          <div class="uc-text-body uc-text-color-text-secondary">
            <p>per year</p>
          </div>
        </div>
        <button
          class="uc-text-label uc-underline"
          on:click={() => toggleModal(Breakdown, true)}
        >
          (incl. taxes & fees)
        </button>
      </div>
      <div class="uc-px-5 mobile:uc-px-size-default-x-spacing">
        <div
          class="uc-bg-white mobile:uc-shadow-primary mobile:uc-rounded-md mobile:uc-p-size-default-x-spacing uc-text-color-text-primary"
        >
          <p
            class="uc-text-label uc-font-bold uc-uppercase uc-text-center uc-mb-size-default-x-spacing uc-text-color-text-primary"
          >
            Your Cover Details
          </p>
          <div
            class="uc-mb-5 uc-text-body uc-text-color-text-primary uc-flex uc-justify-between"
          >
            <p class="uc-text-color-text-secondary">Policy Period</p>
            <p class="uc-font-bold">
              {format(startDate, "dd MMM yyyy")} - {format(
                endDate,
                "dd MMM yyyy",
              )}
            </p>
          </div>
          <a
            href={Data.links["Fact Sheet"].href}
            target="_blank"
            class={`uc-flex uc-items-center uc-justify-between uc-h-10 uc-mb-size-tiny-x-spacing uc-border uc-border-solid uc-border-columbia-blue uc-rounded-md uc-w-full uc-p-size-tiny-x-spacing`}
          >
            <span
              class="uc-text-label uc-text-color-text-primary uc-opacity-80"
            >
              Read the Fact Sheet
            </span>
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.01001 4.80467L1.04657 9.60933L9.16073e-06 8.59625L3.9169 4.80467L9.49243e-06 1.01308L1.04657 -2.16809e-07L6.01001 4.80467Z"
                fill="#252A41"
              />
            </svg>
          </a>
          <a
            href={Data.links["Product Disclosure Statement"].href}
            target="_blank"
            class={`uc-flex uc-items-center uc-justify-between uc-h-10 uc-mb-size-default-x-spacing uc-border uc-border-solid uc-border-columbia-blue uc-rounded-md uc-w-full uc-p-size-tiny-x-spacing`}
          >
            <span
              class="uc-text-label uc-text-color-text-primary uc-opacity-80"
            >
              Read the Product Disclosure Statement
            </span>
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.01001 4.80467L1.04657 9.60933L9.16073e-06 8.59625L3.9169 4.80467L9.49243e-06 1.01308L1.04657 -2.16809e-07L6.01001 4.80467Z"
                fill="#252A41"
              />
            </svg>
          </a>
          <Tabs>
            <TabList>
              <Tab style="uc-rounded-l-md uc-border-l">Cover Includes</Tab>
              <Tab style="uc-rounded-r-md uc-border-r">Cover Excludes</Tab>
            </TabList>

            <TabPanel>
              <p
                class="uc-text-body uc-font-semibold uc-text-color-text-secondary"
              >
                COVID-19 Insurance Payout of <span
                  class="uc-text-color-text-primary uc-font-semibold"
                  >$5,000</span
                >
              </p>
              <ul class="uc-mt-4">
                {#each coverIncludes as pro}
                  <li class="uc-flex">
                    <img
                      src={`${APP_BASE_URL}/assets/tick.png`}
                      class="uc-h-4 uc-w-4 uc-mt-3"
                      alt="tick"
                    />
                    <p
                      class="uc-text-body uc-font-normal uc-text-color-text-secondary uc-ml-size-tiny-x-spacing uc-my-2"
                    >
                      {pro}
                    </p>
                  </li>
                {/each}
              </ul>
            </TabPanel>

            <TabPanel>
              <p
                class="uc-text-body uc-font-normal uc-text-color-text-secondary"
              >
                You don't have cover where you:
              </p>
              <ul class="uc-mt-4">
                {#each coverExcludes as cons}
                  <li class="uc-flex">
                    <img
                      src={`${APP_BASE_URL}/assets/tick.png`}
                      class="uc-h-4 uc-w-4 uc-mt-3"
                      alt="tick"
                    />
                    <p
                      class="uc-text-body uc-font-normal uc-text-color-text-secondary uc-ml-size-tiny-x-spacing uc-my-2"
                    >
                      {cons}
                    </p>
                  </li>
                {/each}
              </ul>
            </TabPanel>
          </Tabs>
        </div>
        <div
          class="uc-flex uc-mt-5 uc-mb-size-default-y-spacing uc-items-start"
        >
          <input
            id="checkbox"
            type="checkbox"
            bind:checked={isChecked}
            class="uc-accent-color-secondary uc-mt-1 uc-mr-4 uc-min-w-[20px]"
          />
          <label
            for="checkbox"
            class="uc-normal-case uc-text-footer uc-text-color-text-philippine-silver uc-leading-relaxed"
          >
            By confirming your payment, you acknowledge you have read and
            understood your
            <span
              class="uc-text-color-text-danger uc-cursor-pointer"
              on:click={() => toggleModal(Duty, true)}
            >
              Duty not to Misrepresent
            </span>, the
            <a
              href={Data.links["Product Disclosure Statement"].href}
              target="_blank"
            >
              <span class="uc-text-color-text-danger"
                >{Data.links["Product Disclosure Statement"].title}</span
              ></a
            >,
            <a
              href={Data.links["Financial Service Guide"].href}
              target="_blank"
            >
              <span class="uc-text-color-text-danger"
                >{Data.links["Financial Service Guide"].title}</span
              ></a
            >
            and
            <a
              href={Data.links["Target Market Determination"].href}
              target="_blank"
              ><span class="uc-text-color-text-danger"
                >{Data.links["Target Market Determination"].title}</span
              ></a
            >
          </label>
        </div>
      </div>
    </div>
    <div
      class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-py-5 uc-border-t-half uc-border-color-columbia-blue"
    >
      <Button disabled={!isChecked} buttonAction={handleClick}
        >Confirm Details</Button
      >
    </div>
  </div>
{/if}
