<script lang="ts">
  // import type {SvelteComponent} from "svelte/internal";
  export let Component;
  export let isLoading = false;
  export let onClickAction = () => console.log("clicked");
</script>

<div
  on:click={onClickAction}
  class={`uc-cursor-pointer ${isLoading ? "hover:uc-cursor-not-allowed" : ""}`}
>
  <svelte:component this={Component} />
</div>
