<script lang="ts">
  import * as yup from "yup";
  import {onMount} from "svelte";
  import {format} from "date-fns";
  import Input from "./Input.svelte";
  import valid from "card-validator";
  import Button from "./Button.svelte";
  import {fade} from "svelte/transition";
  import price from "../utils/constants";
  import {payment} from "../api/covidcare";
  import type {UserI} from "../interfaces/User";
  import {APP_BASE_URL} from "../utils/constants";
  import SVGComponent from "./SVGComponent.svelte";
  import CreditCardInput from "./CreditCardInput.svelte";
  import {formatAmount} from "../utils/currencyFormatter";
  import {formatCard, formatExpiry} from "../utils/formatter";
  import ModalCrosshair from "./../assets/modalcrosshair.svelte";
  import {userDetails, partnerId, policy, step} from "../stores/stores";
  import {ValidationError} from "yup";

  let isLoading = false;

  let user: UserI;

  let partner = "";

  const cardDetails = {
    nameOnCard: "",
    cardNumber: "",
    expiry: "",
    cvc: "",
  };

  onMount(async () => {
    userDetails.subscribe(value => {
      user = value;
      cardDetails.nameOnCard = `${value.firstName} ${value.lastName}`;
    });
    partnerId.subscribe(value => (partner = value));
  });

  $: cardType = {
    cvvSize: 3,
    type: "card",
  };

  export let closeModal: (isLoading: boolean) => void;

  const errors = {
    nameOnCard: "",
    cardNumber: "",
    expiry: "",
    cvc: "",
  };

  const serverError = {
    isError: false,
    message: "",
  };

  const schema = yup.object().shape({
    nameOnCard: yup.string().required("Enter full name"),
  });

  $: invalid = async name => {
    try {
      await yup.reach(schema, name).validate(cardDetails[name]);
      errors[name] = "";
    } catch (err) {
      errors[name] = err.message;
    }
  };

  const handleNameChange = e => {
    cardDetails.nameOnCard = e.target.value;
    invalid("nameOnCard");
  };

  const cardNumberChange = e => {
    const value = e.target.value;

    const finalNumber = formatCard(value);

    cardDetails.cardNumber = finalNumber;

    cardType = {
      cvvSize: valid.number(cardDetails.cardNumber).card?.code.size || 3,
      type: valid.number(cardDetails.cardNumber).card?.type || "card",
    };

    validateCreditCardNumber();
  };

  const cardExpiryChange = e => {
    const value = e.target.value;

    const finalNumber = formatExpiry(value);

    cardDetails.expiry = finalNumber;

    validateCreditCardExpiry();
  };

  const cardCvcChange = e => {
    cardDetails.cvc = e.target.value;

    validateCreditCardCVV();
  };

  const validateCreditCardNumber = async () => {
    const numberValidation = valid.number(cardDetails.cardNumber);
    if (!numberValidation.isValid) {
      errors.cardNumber = "Card Number is not valid";
    } else {
      errors.cardNumber = "";
    }
  };

  const validateCreditCardExpiry = async () => {
    const expiryValidation = valid.expirationDate(
      cardDetails.expiry.toString(),
    );
    if (!expiryValidation.isValid) {
      errors.expiry = "Expiry Date is not valid";
    } else {
      errors.expiry = "";
    }
  };

  const validateCreditCardCVV = async () => {
    const cvvValidation = valid.cvv(
      cardDetails.cvc.toString(),
      cardType.cvvSize,
    );
    if (!cvvValidation.isValid) {
      errors.cvc = "CVV is not valid";
    } else {
      errors.cvc = "";
    }
  };

  const handlePayment = async () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      dateOfBirth,
      email,
      postcode,
      state,
      suburb,
    } = user;
    const {nameOnCard, cardNumber, expiry, cvc} = cardDetails;
    const [expiryMonth, expiryYear] = expiry.split("/");
    const data = {
      firstName,
      lastName,
      email,
      postcode,
      state,
      suburb,
      nameOnCard,
      cvc,
      expiryMonth,
      expiryYear,
      dateOfBirth: format(new Date(dateOfBirth), "dd/MM/yyyy"),
      mobileNumber: `0${mobileNumber.split(" ").join("")}`,
      cardNumber: cardNumber.split(" ").join(""),
      partnerId: partner,
    };

    await window.analytics.track("Pay $122.45", {
      plan: "Covid BounceBack",
    });

    try {
      const res = await payment(data);
      if (res.status === 201) {
        policy.set(res.data);
        step.set(5);
      } else {
        serverError.isError = true;
        serverError.message = res.data.message;

        setTimeout(() => {
          serverError.isError = false;
          serverError.message = "";
        }, 5000);
      }
    } catch (e) {
      serverError.isError = true;
      serverError.message = "Network Error";

      setTimeout(() => {
        serverError.isError = false;
        serverError.message = "";
      }, 5000);
    }
  };

  const handleSubmit = async () => {
    try {
      isLoading = true;

      await schema.validate(cardDetails, {abortEarly: false});
      validateCreditCardNumber();
      validateCreditCardExpiry();
      validateCreditCardCVV();

      if (Object.values(errors).every(x => x === "")) {
        await handlePayment();
      }
    } catch (err) {
      if (err instanceof ValidationError) {
        errors.nameOnCard = err.inner.reduce((acc, err) => {
          return `${err.message}${acc} `;
        }, "");
      }
    } finally {
      isLoading = false;
    }
  };
</script>

<div
  class="uc-bg-color-primary/50 uc-absolute uc-inset-0 uc-w-full uc-h-full uc-z-10"
>
  <div
    class="uc-absolute uc-bottom-0 uc-w-full uc-bg-white uc-rounded-t-3xl uc-pt-12 uc-pb-size-default-x-spacing uc-px-5"
  >
    <div class="uc-absolute uc-top-5 uc-right-5">
      <SVGComponent
        Component={ModalCrosshair}
        {isLoading}
        onClickAction={() => closeModal(isLoading)}
      />
    </div>
    <h1
      class="uc-text-left uc-text-label uc-font-bold uc-text-color-text-primary uc-mb-[22px] uc-uppercase"
    >
      Your Payment Details
    </h1>
    <div class="uc-flex uc-items-center">
      <p class="uc-font-medium uc-text-[10px] uc-text-color-text-header">
        WE ACCEPT
      </p>
      <img
        class="uc-ml-3"
        height="18px"
        width="30px"
        src={`${APP_BASE_URL}/assets/visa.png`}
        alt="visa"
      />
      <img
        class="uc-ml-3"
        height="20.57px"
        width="30px"
        src={`${APP_BASE_URL}/assets/mastercard.png`}
        alt="mastercard"
      />
      <img
        class="uc-ml-3"
        height="20.57px"
        width="30px"
        src={`${APP_BASE_URL}/assets/amex.png`}
        alt="amex"
      />
    </div>
    <form
      class="uc-mt-size-default-x-spacing uc-mb-5"
      on:submit|preventDefault={handleSubmit}
    >
      <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
        <Input
          id="name"
          type="text"
          name="nameOnCard"
          label="name on the card"
          placeholder="Name on the card"
          value={cardDetails.nameOnCard}
          handleChange={handleNameChange}
          errored={errors.nameOnCard}
          errorMessage={errors.nameOnCard}
        />
      </div>
      <div class="uc-w-full uc-mb-9 uc-flex uc-gap-x-3.5">
        <CreditCardInput
          {cardType}
          cardNumber={cardDetails.cardNumber}
          {cardNumberChange}
          expiry={cardDetails.expiry}
          {cardExpiryChange}
          cvc={cardDetails.cvc}
          {cardCvcChange}
          {errors}
        />
        {#if serverError.isError}
          <small
            out:fade
            class="uc-absolute uc-bottom-36 uc-text-footer2 uc-transition-all 
          uc-text-color-text-danger/80  uc-uppercase"
            >{serverError.message}</small
          >
        {/if}
      </div>
      <Button {isLoading} type="submit"
        >Pay {formatAmount(
          (price.amount * 100 + price.decimal) / 100,
          2,
        )}</Button
      >
    </form>
    <div class="uc-flex uc-items-center">
      <img
        class="uc-w-size-default-x-spacing uc-h-size-default-x-spacing uc-mr-size-tiny-x-spacing"
        src={`${APP_BASE_URL}/assets/securepayment.png`}
        alt="securepayment"
      />
      <p class="uc-font-normal uc-text-color-text-header uc-text-xs">
        Card details will be saved securely, based of the industry standard.
        Payment infrastructure is provided by <span
          class="uc-font-extrabold uc-text-color-medium-slate-blue">stripe</span
        >.
      </p>
    </div>
  </div>
</div>
