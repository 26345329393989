<script lang="ts">
  import {component, showModal, showCross} from "../stores/stores";
  import ModalCrosshair from "../assets/modalcrosshair.svelte";
  import SVGComponent from "./SVGComponent.svelte";

  let modalContent;
  let showModalCross: boolean;

  const closeModal = () => {
    showModal.set(false);
    component.set(null);
  };

  component.subscribe(value => {
    modalContent = value;
  });
  showCross.subscribe(value => {
    showModalCross = value;
  });
</script>

<div
  class="uc-bg-color-primary/50 uc-absolute uc-top-0 uc-left-0 uc-rounded-md uc-overscroll-hidden uc-w-full uc-h-full uc-flex uc-items-center uc-justify-center uc-z-10"
>
  <div class="uc-bg-white uc-rounded-md uc-relative uc-h-min uc-w-4/5">
    {#if showModalCross}
      <div class="uc-absolute uc-top-2.5 uc-right-2.5">
        <SVGComponent Component={ModalCrosshair} onClickAction={closeModal} />
      </div>
    {/if}
    <svelte:component this={modalContent} on:click={closeModal} />
  </div>
</div>
