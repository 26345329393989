var _a, _b, _c, _d, _e, _f;
import { writable } from "svelte/store";
/* eslint-disable require-jsdoc */
function getStep() {
    if (Number(localStorage.getItem("step")) >= 1 &&
        Number(localStorage.getItem("step")) <= 5) {
        return localStorage.getItem("step");
    }
    return null;
}
export const stepsStateRef = writable([]);
const storedStep = Number(JSON.parse((_a = getStep()) !== null && _a !== void 0 ? _a : "1"));
export const step = writable(storedStep);
step.subscribe(val => {
    localStorage.setItem("step", JSON.stringify(val));
});
const storedEligibility = JSON.parse((_b = localStorage.getItem("eligibility")) !== null && _b !== void 0 ? _b : "[]");
export const eligibility = writable(storedEligibility);
eligibility.subscribe(val => {
    localStorage.setItem("eligibility", JSON.stringify(val));
});
const storedChecked = JSON.parse((_c = localStorage.getItem("checked")) !== null && _c !== void 0 ? _c : "false");
export const checked = writable(storedChecked);
checked.subscribe(val => {
    localStorage.setItem("checked", JSON.stringify(val));
});
export const partnerId = writable("");
export const policy = writable({
    policyExpiryDate: new Date().toString(),
    policyStartDate: new Date().toString(),
    policyId: "",
});
const storedUser = JSON.parse((_d = localStorage.getItem("user")) !== null && _d !== void 0 ? _d : '{"firstName":"","lastName":"","mobileNumber":"","dateOfBirth":"","email":"","postcode":"","state":"","suburb":"","locality":""}');
export const userDetails = writable(storedUser);
userDetails.subscribe(val => {
    localStorage.setItem("user", JSON.stringify(val));
});
const storedCompleted = JSON.parse((_e = localStorage.getItem("completed")) !== null && _e !== void 0 ? _e : "false");
export const completed = writable(storedCompleted);
completed.subscribe(val => {
    localStorage.setItem("completed", JSON.stringify(val));
});
const storedIdentityId = JSON.parse((_f = localStorage.getItem("identityId")) !== null && _f !== void 0 ? _f : `""`);
export const identityId = writable(storedIdentityId);
identityId.subscribe(val => {
    localStorage.setItem("identityId", JSON.stringify(val));
});
export const showLoader = writable(false);
export const loaderSrc = writable("1st stage B&W.gif");
export const showModal = writable(false);
export const showCross = writable(true);
export const component = writable();
