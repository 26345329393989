<script lang="ts">
  import {onMount} from "svelte";
  import {format} from "date-fns";
  import price from "../../utils/constants";
  import {APP_BASE_URL} from "../../utils/constants";
  import Footer from "../../components/Footer.svelte";
  import Header from "../../components/Header.svelte";
  import {formatAmount} from "../../utils/currencyFormatter";
  import {
    userDetails,
    policy,
    completed,
    identityId,
    partnerId,
  } from "../../stores/stores";

  onMount(async () => {
    await window.analytics.track("Successful Payment ", {
      plan: "Covid BounceBack",
    });

    await window.analytics.identify($identityId, {
      policy_end_date: $policy.policyExpiryDate,
      policy_start_date: $policy.policyStartDate,
      policy_id: $policy.policyId,
      recent_partner_id: $partnerId,
      sku: "Covid Insurance",
      Status: "PURCHASED",
      ...($partnerId !== "upcover" && {[`partner_${$partnerId}`]: "yes"}),
    });

    completed.set(true);
  });
</script>

<div class="uc-h-full uc-grid uc-grid-rows-[58px_1fr]">
  <Header />
  <div class="uc-overflow-y-scroll">
    <div class="uc-px-5 mobile:uc-px-size-default-x-spacing">
      <div class="uc-flex uc-flex-col uc-items-center uc-text-center">
        <h1
          class="uc-text-header uc-font-extrabold uc-text-color-text-primary uc-mt-size-default-x-spacing uc-mb-5 uc-leading-none"
        >
          Congratulations!
        </h1>
        <p
          class="uc-text-body uc-text-color-text-primary uc-font-semibold uc-mb-7"
        >
          You are now covered.
        </p>
        <p
          class="uc-text-body uc-font-normal uc-text-color-text-secondary uc-mb-10"
        >
          Please check your email <span
            class="uc-font-semibold uc-text-color-text-primary "
            >({$userDetails.email})</span
          >
          for more details
        </p>
        <img
          src={`${APP_BASE_URL}/assets/payment-success.png`}
          height="138"
          width="120"
          alt="payment successful"
        />
        <p
          class="uc-text-body uc-text-color-text-primary uc-font-semibold uc-mt-5 uc-mb-size-default-x-spacing"
        >
          Payment successful
        </p>
        <p
          class="uc-text-body uc-text-color-text-primary uc-font-bold uc-mb-size-default-x-spacing"
        >
          COVID Insurance Policy
        </p>
      </div>
    </div>
    <div
      class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-mb-size-default-x-spacing"
    >
      <div
        class="uc-bg-white uc-shadow-primary uc-rounded-md uc-p-size-default-x-spacing uc-text-color-text-primary uc-text-label"
      >
        <div class="uc-border-b-half uc-border-color-columbia-blue">
          <p class="uc-font-semibold uc-mb-size-tiny-x-spacing">Policy ID</p>
          <p class="uc-font-medium uc-mb-size-tiny-x-spacing">
            {$policy.policyId}
          </p>
        </div>
        <div class="uc-border-b-half uc-border-color-columbia-blue">
          <p class="uc-font-semibold uc-my-size-tiny-x-spacing">
            Name of Customer
          </p>
          <p class="uc-font-medium uc-mb-size-tiny-x-spacing">
            {`${$userDetails.firstName} ${$userDetails.lastName}`}
          </p>
        </div>
        <div class="uc-border-b-half uc-border-color-columbia-blue">
          <p class="uc-font-semibold uc-my-size-tiny-x-spacing">
            Class of Cover
          </p>
          <div class="uc-flex uc-items-center uc-mb-size-tiny-x-spacing">
            <div
              class="uc-w-1.5 uc-h-1.5 uc-rounded-full uc-bg-color-secondary uc-mr-3"
            />
            <p class="uc-font-medium">COVID BounceBack Insurance Policy</p>
          </div>
        </div>
        <div class="uc-border-b-half uc-border-color-columbia-blue">
          <p class="uc-font-semibold uc-my-size-tiny-x-spacing">
            Period of Insurance
          </p>
          <p class="uc-font-medium uc-mb-size-tiny-x-spacing">
            {format(
              new Date($policy.policyStartDate.split("/").reverse().join("/")),
              "MMMM dd, yyyy",
            )} -
            {format(
              new Date($policy.policyExpiryDate.split("/").reverse().join("/")),
              "MMMM dd, yyyy",
            )}
          </p>
        </div>
        <div
          class="uc-flex uc-justify-between uc-font-bold uc-mt-size-tiny-x-spacing uc-text-body"
        >
          <p>Total Paid</p>
          <p class="uc-text-color-text-danger">
            {formatAmount((price.amount * 100 + price.decimal) / 100, 2)}
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</div>
