<script lang="ts">
  import * as yup from "yup";
  import {onMount} from "svelte";
  import {format, sub} from "date-fns";
  import price from "../../utils/constants";
  import type {UserI} from "../../interfaces/User";

  import Input from "../../components/Input.svelte";
  import Header from "../../components/Header.svelte";
  import Button from "../../components/Button.svelte";
  import Payment from "../../components/Payment.svelte";
  import Breakdown from "../../ModalPages/Breakdown.svelte";
  import {formatAmount} from "../../utils/currencyFormatter";
  import type {Location} from "../../interfaces/SelectOption";
  import PhoneInput from "../../components/PhoneInput.svelte";
  import {formatPhone, validateInput} from "../../utils/formatter";
  import SearchableSelect from "../../components/SearchableSelect.svelte";

  import {
    identityId,
    component,
    showModal,
    showCross,
    userDetails,
    partnerId,
  } from "../../stores/stores";

  const ageLimit = sub(new Date(), {years: 18});

  const toggleModal = (modalComponent, showModalCross = true) => {
    component.set(modalComponent);
    showModal.set(true);
    showCross.set(showModalCross);
  };
  let values: UserI = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    dateOfBirth: "",
    email: "",
    postcode: "",
    state: "",
    suburb: "",
    locality: "",
  };
  let errors = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    dateOfBirth: "",
    email: "",
    locality: "",
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("Enter First Name"),
    lastName: yup.string().required("Enter Last Name"),
    mobileNumber: yup
      .string()
      .required("Enter Phone Number")
      .matches(/^4/i, "Number should start with a 4")
      .min(11, "Phone Number should be 9 digits")
      .max(11, "Phone Number should be 9 digits"),
    dateOfBirth: yup.string().required("Enter DOB"),
    email: yup.string().required("Enter Email").email("Email is invalid"),
    locality: yup.string().required("SUBURB/ TOWN/ POSTCODE"),
    // postcode: yup.string().required("Enter Postcode"),
    // suburb: yup.string().required("Enter Suburb"),
    // state: yup.string().required("Enter State"),
  });

  onMount(async () => {
    userDetails.subscribe(value => {
      values = value;
    });

    await window.analytics.track("Contact Details", {
      plan: "Covid BounceBack",
    });
  });

  $: invalid = async (name: string) => {
    try {
      await yup.reach(schema, name).validate(values[name]);
      errors[name] = "";
    } catch (err) {
      errors[name] = err.message;
    }
  };

  const handleChange = e => {
    values[e.target.name] = e.target.value;
    invalid(e.target.name);
  };

  const onSelect = (name: string, value: string, obj: Location) => {
    values[name] = value;
    Object.entries(obj).map(([key, value]) => {
      values[key] = value;
      // invalid(key);
    });
    invalid(name);
  };

  const handlePhoneChange = e => {
    const value = e.target.value;

    const finalNumber = formatPhone(value);

    values.mobileNumber = finalNumber;
    invalid(e.target.name);
  };

  const handleSubmit = async () => {
    try {
      await schema.validate(values, {abortEarly: false});
      errors = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        dateOfBirth: "",
        email: "",
        locality: "",
      };
      userDetails.set(values);
      await window.analytics.track("Proceed to Pay", {
        plan: "Covid BounceBack",
      });
      await window.analytics.identify($identityId, {
        name: `${values.firstName} ${values.lastName}`,
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.mobileNumber,
        email: values.email,
        date_of_birth: values.dateOfBirth,
        postcode: values.postcode,
        state: values.state,
        suburb: values.suburb,
        locality: values.locality,
        recent_partner_id: $partnerId,
        sku: "Covid Insurance",
        Status: "PROCEED TO PAY",
        ...($partnerId !== "upcover" && {[`partner_${$partnerId}`]: "yes"}),
      });
      showPayment = true;
    } catch (err) {
      errors = err.inner.reduce((acc, err) => {
        return {...acc, [err.path]: err.message};
      }, {});
    }
  };

  $: showPayment = false;

  const closeModal = (isLoading: boolean) => {
    if (isLoading) return;
    showPayment = false;
  };
</script>

<div class="uc-h-full">
  {#if showPayment}
    <Payment {closeModal} />
  {/if}

  <form class="uc-h-full" on:submit|preventDefault={handleSubmit}>
    <div class="uc-h-full uc-grid uc-grid-rows-[58px_1fr_90px]">
      <Header />
      <div class="uc-overflow-y-scroll">
        <div
          class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-mb-size-default-x-spacing uc-text-color-text-primary"
        >
          <div class="uc-mt-size-default-y-spacing">
            <p
              class="uc-text-body uc-font-semibold uc-text-color-text-secondary"
            >
              Please confirm your personal details before proceeding to pay for
              policy
            </p>
          </div>
          <div class="uc-flex uc-items-center">
            <p class="uc-text-amount uc-text-color-text-primary uc-font-black">
              {formatAmount(price.amount, 0)}
              <sup
                class="uc-font-semibold uc-text-superscript uc--top-5 uc--left-2.5"
                >.{price.decimal}</sup
              >
            </p>
            <div class="uc-text-body uc-text-color-text-secondary">
              <p>per year</p>
            </div>
          </div>
          <button
            type="button"
            class="uc-text-label uc-underline"
            on:click={() => toggleModal(Breakdown, true)}
          >
            (incl. taxes & fees)
          </button>
        </div>
        <div class="uc-mt-2 uc-px-5 mobile:uc-px-size-default-x-spacing">
          <div
            class="uc-bg-white mobile:uc-shadow-primary mobile:uc-rounded-md mobile:uc-p-size-default-x-spacing uc-text-color-text-primary uc-mb-size-default-y-spacing"
          >
            <p
              class="uc-text-body uc-font-bold uc-uppercase uc-text-center uc-text-color-text-primary uc-mb-size-default-y-spacing"
            >
              Your Personal Details
            </p>
            <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
              <Input
                styleClass="uc-basis-1/2"
                id="firstName"
                type="text"
                name="firstName"
                label="first name"
                placeholder="first name"
                value={values.firstName}
                {handleChange}
                errored={errors.firstName}
                errorMessage={errors.firstName}
              />
              <Input
                styleClass="uc-basis-1/2"
                id="lastName"
                type="text"
                name="lastName"
                label="last name"
                placeholder="last name"
                value={values.lastName}
                {handleChange}
                errored={errors.lastName}
                errorMessage={errors.lastName}
              />
            </div>
            <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
              <PhoneInput
                styleClass="uc-basis-3/5"
                id="mobileNumber"
                type="text"
                name="mobileNumber"
                label="phone number"
                placeholder="phone number"
                value={values.mobileNumber}
                {handlePhoneChange}
                {validateInput}
                errored={errors.mobileNumber}
                errorMessage={errors.mobileNumber}
              />
              <Input
                styleClass="uc-basis-2/5"
                id="dateOfBirth"
                type="date"
                name="dateOfBirth"
                label="DOB"
                placeholder="DD/MM/YYYY"
                max={format(ageLimit, "yyyy-MM-dd")}
                value={values.dateOfBirth}
                {handleChange}
                errored={errors.dateOfBirth}
                errorMessage={errors.dateOfBirth}
              />
            </div>
            <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
              <Input
                styleClass="uc-w-full"
                id="email"
                type="email"
                name="email"
                label="email"
                placeholder="email"
                value={values.email}
                {handleChange}
                errored={errors.email}
                errorMessage={errors.email}
              />
            </div>
            <div class="uc-w-full uc-flex uc-gap-x-3.5">
              <SearchableSelect
                styleClass="uc-w-full"
                id="locality"
                type="text"
                name="locality"
                label="SUBURB/ TOWN/ POSTCODE"
                placeholder="Search by suburb or postcode"
                value={values.locality}
                {onSelect}
                errored={errors.locality}
                errorMessage={errors.locality}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="uc-px-5 mobile:uc-px-size-default-x-spacing uc-py-5 uc-border-t-half uc-border-color-columbia-blue"
      >
        <Button type="submit">Proceed to Pay</Button>
      </div>
    </div>
  </form>
</div>
