<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.667 5.9974C9.78294 5.9974 8.93509 6.34859 8.30997 6.97371C7.68485 7.59883 7.33366 8.44667 7.33366 9.33073V13.3307H8.66699V9.33073C8.66699 8.8003 8.87771 8.29159 9.25278 7.91652C9.62785 7.54144 10.1366 7.33073 10.667 7.33073C11.1974 7.33073 11.7061 7.54144 12.0812 7.91652C12.4563 8.29159 12.667 8.8003 12.667 9.33073V13.3307H14.0003V9.33073C14.0003 8.44667 13.6491 7.59883 13.024 6.97371C12.3989 6.34859 11.551 5.9974 10.667 5.9974ZM10.667 4.66406C9.42931 4.66406 8.24233 5.15573 7.36716 6.0309C6.49199 6.90607 6.00033 8.09305 6.00033 9.33073V13.9974C6.00033 14.3656 6.2988 14.6641 6.66699 14.6641H9.33366C9.70185 14.6641 10.0003 14.3656 10.0003 13.9974V9.33073C10.0003 9.15392 10.0706 8.98435 10.1956 8.85933C10.3206 8.7343 10.4902 8.66406 10.667 8.66406C10.8438 8.66406 11.0134 8.7343 11.1384 8.85933C11.2634 8.98435 11.3337 9.15392 11.3337 9.33073V13.9974C11.3337 14.3656 11.6321 14.6641 12.0003 14.6641H14.667C15.0352 14.6641 15.3337 14.3656 15.3337 13.9974V9.33073C15.3337 8.09305 14.842 6.90607 13.9668 6.0309C13.0917 5.15573 11.9047 4.66406 10.667 4.66406Z"
    fill="#545464"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.666992 5.9974C0.666992 5.62921 0.965469 5.33073 1.33366 5.33073H4.00033C4.36852 5.33073 4.66699 5.62921 4.66699 5.9974V13.9974C4.66699 14.3656 4.36852 14.6641 4.00033 14.6641H1.33366C0.965469 14.6641 0.666992 14.3656 0.666992 13.9974V5.9974ZM2.00033 6.66406V13.3307H3.33366V6.66406H2.00033Z"
    fill="#545464"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.66699 1.9974C2.2988 1.9974 2.00033 2.29587 2.00033 2.66406C2.00033 3.03225 2.2988 3.33073 2.66699 3.33073C3.03518 3.33073 3.33366 3.03225 3.33366 2.66406C3.33366 2.29587 3.03518 1.9974 2.66699 1.9974ZM0.666992 2.66406C0.666992 1.55949 1.56242 0.664062 2.66699 0.664062C3.77156 0.664062 4.66699 1.55949 4.66699 2.66406C4.66699 3.76863 3.77156 4.66406 2.66699 4.66406C1.56242 4.66406 0.666992 3.76863 0.666992 2.66406Z"
    fill="#545464"
  />
</svg>
