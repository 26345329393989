<script lang="ts">
  export let id = "id";
  export let name = "name";
  export let value = "value";
  export let label = "label";
  export let disabled = false;
  export let checked: boolean;
  export let group: string[];

  $: updateChekbox(group);
  $: updateGroup(checked);

  const updateChekbox = (group: string[]) => {
    checked = group.indexOf(value) >= 0;
  };

  const updateGroup = (checked: boolean) => {
    const index = group.indexOf(value);
    if (checked) {
      if (index < 0) {
        group.push(value);
        group = group;
      }
    } else {
      if (index >= 0) {
        group.splice(index, 1);
        group = group;
      }
    }
  };
</script>

<div
  class={`uc-flex uc-items-start uc-mb-size-tiny-x-spacing uc-bg-color-columbia-blue/10 uc-border uc-border-solid uc-border-columbia-blue uc-rounded-md uc-w-full uc-py-3 uc-px-5 ${
    !checked ? "uc-opacity-50" : ""
  }`}
>
  <input
    type="checkbox"
    class="uc-accent-color-primary uc-mt-1 uc-min-w-[20px]"
    bind:checked
    {id}
    {name}
    {value}
    {disabled}
  />
  <label
    class="uc-ml-size-tiny-x-spacing uc-text-label uc-normal-case uc-text-color-text-secondary"
    for={id}>{label}</label
  >
</div>
