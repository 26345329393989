<script lang="ts">
  import Pay from "./pages/Pay/index.svelte";
  import Start from "./pages/Start/index.svelte";
  import Details from "./pages/Details/index.svelte";
  import Landing from "./pages/Landing/index.svelte";
  import Completed from "./pages/Completed/index.svelte";

  import {step} from "./stores/stores";

  let pageStep: number;

  step.subscribe(val => (pageStep = val));
</script>

{#if pageStep === 1}
  <Landing />
{:else if pageStep === 2}
  <Start />
{:else if pageStep === 3}
  <Details />
{:else if pageStep === 4}
  <Pay />
{:else if pageStep === 5}
  <Completed />
{/if}
