<svg
  width="21"
  height="22"
  viewBox="0 0 21 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <ellipse cx="10.5" cy="10.85" rx="10.5" ry="10.85" fill="#F2F4F8" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.8632 7.85682C14.0455 8.04514 14.0455 8.35046 13.8632 8.53878L8.26325 14.3254C8.081 14.5138 7.78553 14.5138 7.60328 14.3254C7.42104 14.1371 7.42104 13.8318 7.60328 13.6435L13.2033 7.85682C13.3855 7.6685 13.681 7.6685 13.8632 7.85682Z"
    fill="black"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.60328 7.85682C7.78553 7.6685 8.081 7.6685 8.26325 7.85682L13.8632 13.6435C14.0455 13.8318 14.0455 14.1371 13.8632 14.3254C13.681 14.5138 13.3855 14.5138 13.2033 14.3254L7.60328 8.53878C7.42104 8.35046 7.42104 8.04514 7.60328 7.85682Z"
    fill="black"
  />
</svg>
