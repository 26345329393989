<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.17157 1.83564C7.92172 1.08549 8.93913 0.664062 10 0.664062H12C12.3682 0.664062 12.6667 0.962539 12.6667 1.33073V3.9974C12.6667 4.36559 12.3682 4.66406 12 4.66406H10V5.9974H12C12.2053 5.9974 12.3991 6.09198 12.5255 6.25379C12.6518 6.4156 12.6966 6.62659 12.6468 6.82575L11.9801 9.49242C11.9059 9.7892 11.6392 9.9974 11.3333 9.9974H10V14.6641C10 15.0323 9.70152 15.3307 9.33333 15.3307H6.66667C6.29848 15.3307 6 15.0323 6 14.6641V9.9974H4.66667C4.29848 9.9974 4 9.69892 4 9.33073V6.66406C4 6.29587 4.29848 5.9974 4.66667 5.9974H6V4.66406C6 3.6032 6.42143 2.58578 7.17157 1.83564ZM10 1.9974C9.29276 1.9974 8.61448 2.27835 8.11438 2.77844C7.61428 3.27854 7.33333 3.95682 7.33333 4.66406V6.66406C7.33333 7.03225 7.03486 7.33073 6.66667 7.33073H5.33333V8.66406H6.66667C7.03486 8.66406 7.33333 8.96254 7.33333 9.33073V13.9974H8.66667V9.33073C8.66667 8.96254 8.96514 8.66406 9.33333 8.66406H10.8128L11.1461 7.33073H9.33333C8.96514 7.33073 8.66667 7.03225 8.66667 6.66406V4.66406C8.66667 4.31044 8.80714 3.9713 9.05719 3.72125C9.30724 3.47121 9.64638 3.33073 10 3.33073H11.3333V1.9974H10Z"
    fill="#545464"
  />
</svg>
