import App from "./App.svelte";
/* eslint-disable require-jsdoc */
class Upcover {
    constructor() {
        this.partnerId = "";
        this.widgetHeightVariableString = "--sizes-widget-height";
        this.widgetWidthVariableString = "--sizes-widget-width";
        this.timeout = 1000000;
        this.target = document.body;
        this.root = document.querySelector(":root");
        this.isWebView = false;
        this.boxShadow = true;
        const rootStyle = getComputedStyle(this.root);
        this.height = rootStyle.getPropertyValue(this.widgetHeightVariableString);
        this.width = rootStyle.getPropertyValue(this.widgetWidthVariableString);
    }
    setTarget(target) {
        this.target = target;
        return this;
    }
    setPartnerId(id) {
        this.partnerId = id;
        return this;
    }
    setHeight(height) {
        this.height = height;
        return this;
    }
    setWidth(width) {
        this.width = width;
        return this;
    }
    setWebView() {
        this.isWebView = true;
        return this;
    }
    setBoxShadow() {
        this.boxShadow = !this.boxShadow;
        return this;
    }
    showIntercom() {
        this.ensureIntercomIsInitialised().then(function () {
            console.info("Intercom initialised. Now calling update method"); // if the promise condition is met, this alert is fired
            /* eslint-disable new-cap */
            Intercom("update", { hide_default_launcher: false });
        });
        return this;
    }
    hideIntercom() {
        this.ensureIntercomIsInitialised().then(function () {
            console.info("Intercom initialised. Now calling update method"); // if the promise condition is met, this alert is fired
            /* eslint-disable new-cap */
            Intercom("update", { hide_default_launcher: true });
        });
        return this;
    }
    ensureIntercomIsInitialised() {
        const timeout = this.timeout;
        const start = Date.now();
        return new Promise(waitForIntercom);
        // set the promise object within the ensureIntercomIsInitialised object
        // waitForIntercom makes the decision whether the condition is met
        // or not met or the timeout has been exceeded which means
        // this promise will be rejected
        function waitForIntercom(resolve, reject) {
            try {
                if (Intercom)
                    resolve(Intercom);
                else if (timeout && Date.now() - start >= timeout)
                    reject(new Error("timeout"));
                /* eslint-disable no-invalid-this */ else
                    setTimeout(waitForIntercom.bind(this, resolve, reject), 30);
            }
            catch (e) {
                if (e instanceof ReferenceError) {
                    if (timeout && Date.now() - start >= timeout)
                        reject(new Error("timeout"));
                    /* eslint-disable no-invalid-this */ else
                        setTimeout(waitForIntercom.bind(this, resolve, reject), 30);
                }
            }
        }
    }
    initialize() {
        const params = new URLSearchParams(window.location.search);
        const partnerId = params.get("partnerId");
        this.app = new App({
            target: this.target,
            props: {
                partner: partnerId || this.partnerId || "upcover",
                isWebView: this.isWebView,
                boxShadow: this.boxShadow,
            },
        });
        this.hideIntercom();
        this.root.style.setProperty(this.widgetHeightVariableString, this.height);
        this.root.style.setProperty(this.widgetWidthVariableString, this.width);
    }
}
export default Upcover;
