<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.66699 1.9974C3.19423 1.9974 2.00033 3.1913 2.00033 4.66406V11.3307C2.00033 12.8035 3.19423 13.9974 4.66699 13.9974H11.3337C12.8064 13.9974 14.0003 12.8035 14.0003 11.3307V4.66406C14.0003 3.1913 12.8064 1.9974 11.3337 1.9974H4.66699ZM0.666992 4.66406C0.666992 2.45492 2.45785 0.664062 4.66699 0.664062H11.3337C13.5428 0.664062 15.3337 2.45492 15.3337 4.66406V11.3307C15.3337 13.5399 13.5428 15.3307 11.3337 15.3307H4.66699C2.45785 15.3307 0.666992 13.5399 0.666992 11.3307V4.66406Z"
    fill="#545464"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.32202 5.99559C7.90589 5.93389 7.48091 6.00497 7.10751 6.19872C6.73411 6.39247 6.43131 6.69902 6.24218 7.07478C6.05305 7.45054 5.98722 7.87637 6.05405 8.2917C6.12088 8.70703 6.31697 9.09071 6.61444 9.38818C6.9119 9.68564 7.29558 9.88173 7.71091 9.94856C8.12624 10.0154 8.55207 9.94956 8.92783 9.76043C9.30359 9.5713 9.61015 9.2685 9.8039 8.8951C9.99765 8.5217 10.0687 8.09672 10.007 7.68059C9.94407 7.25613 9.74629 6.86317 9.44286 6.55975C9.13944 6.25633 8.74648 6.05854 8.32202 5.99559ZM6.49342 5.01522C7.11575 4.6923 7.82406 4.57384 8.5176 4.67668C9.22503 4.78159 9.87997 5.11124 10.3857 5.61694C10.8914 6.12264 11.221 6.77758 11.3259 7.48502C11.4288 8.17856 11.3103 8.88686 10.9874 9.5092C10.6645 10.1315 10.1536 10.6362 9.52728 10.9514C8.90102 11.2666 8.1913 11.3763 7.49908 11.265C6.80687 11.1536 6.16739 10.8268 5.67163 10.331C5.17586 9.83522 4.84904 9.19575 4.73765 8.50353C4.62626 7.81131 4.73598 7.10159 5.0512 6.47533C5.36642 5.84906 5.87108 5.33813 6.49342 5.01522Z"
    fill="#545464"
  />
</svg>
