<script lang="ts">
  import SvgComponent from "./SVGComponent.svelte";
  import location from "../assets/location.svelte";
  import crosshair from "../assets/crosshair.svelte";
  import SearchIcon from "../assets/searchicon.svelte";
  import type {LocationOption, Location} from "../interfaces/SelectOption";

  let searchPostCodeLocality: (val: string) => LocationOption[];

  const initializePostalCodes = () => {
    searchPostCodeLocality = window.searchPostCodeLocality;
  };

  export let id = "id";
  export let type = "text";
  export let name = "name";
  export let placeholder = "placholder";
  export let styleClass = "";
  export let value = "";
  export let label = "label";
  export let errored: boolean | string = false;
  export let errorMessage = "enter valid details";

  export let onSelect: (name: string, value: string, obj: Location) => void;

  let val = "";
  let timer;

  const debounce = v => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      val = v;
    }, 1000);
  };

  const onInput = e => {
    const userInput = e.target.value;
    debounce(userInput);

    formResults = [];
  };

  $: if (val.length > 2) {
    const results = searchPostCodeLocality(val);

    formResults = results;
  }

  const resultsOnClick = result => {
    const {locality, state, postcode} = result;
    const setValue = `${locality}, ${state}, ${postcode}`;
    onSelect(name, setValue, {suburb: locality, state, postcode});
    formResults = [];
  };

  $: isFocused = false;
  let formResults: LocationOption[] = [];
</script>

<svelte:head>
  <script
    src="https://cdn.jsdelivr.net/gh/upcover-pty-ltd/aus-post-code/postcodes.min.js"
    on:load={initializePostalCodes}></script>
</svelte:head>

<div
  class={`${styleClass} uc-flex uc-flex-col uc-h-button-height uc-border uc-border-solid ${
    errored ? "uc-border-color-secondary" : "uc-border-color-columbia-blue"
  } uc-rounded-md uc-w-full uc-py-2`}
>
  <div class="uc-relative">
    <input
      {type}
      on:keyup={onInput}
      on:focus={() => (isFocused = true)}
      class="uc-absolute uc-top-3.5 uc-left-4 uc-block uc-peer uc-w-10/12 uc-placeholder-transparent uc-appearance-none uc-text-color-text-primary uc-text-label uc-bg-transparent focus:uc-outline-none"
      {id}
      {name}
      {value}
      {placeholder}
    />
    <label
      class={`uc-absolute uc-top-0 uc-left-4 uc-text-footer2 uc-transition-all peer-focus:uc-top-0 peer-focus:uc-text-footer2
       ${
         errored
           ? "peer-placeholder-shown:uc-top-2.5 uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-color-text-danger/80 peer-focus:uc-text-color-text-danger/80 peer-placeholder-shown:uc-text-footer2"
           : "peer-placeholder-shown:uc-top-2 uc-text-color-text-primary/80 peer-placeholder-shown:uc-text-color-text-primary/80 peer-focus:uc-text-color-text-primary/80 peer-placeholder-shown:uc-text-footer"
       } uc-uppercase`}
      for={id}>{errored ? errorMessage : label}</label
    >
    <div class="uc-absolute uc-right-4 uc-top-2">
      {#if value != ""}
        <SvgComponent
          onClickAction={() => {
            onSelect(name, "", {suburb: "", state: "", postcode: ""});
          }}
          Component={crosshair}
        />
      {:else}
        <SvgComponent
          onClickAction={() => {
            document.getElementById(id)?.focus();
          }}
          Component={SearchIcon}
        />
      {/if}
    </div>
    {#if isFocused && formResults.length > 0}
      <ul
        class="uc-absolute uc-max-h-[206px] mobile:uc-w-[314px] uc-overflow-y-auto uc-bottom-3.5 uc--inset-x-px uc-bg-white uc-border uc-border-solid uc-border-color-columbia-blue uc-rounded-lg uc-p-size-tiny-x-spacing"
      >
        {#each formResults as result, index}
          <li
            on:click={() => resultsOnClick(result)}
            class={`uc-flex uc-items-center uc-cursor-pointer ${
              index === formResults.length - 1 ? "" : "uc-mb-6"
            }`}
          >
            <SvgComponent Component={location} />
            <p
              class="uc-ml-2.5 uc-text-color-text-primary uc-font-semibold uc-text-label"
            >
              {result.locality},
              <span class="uc-font-normal uc-text-color-text-secondary"
                >{result.state}, {result.postcode}</span
              >
            </p>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
</div>
