<script>
  import {stepsStateRef} from "../stores/stores.js";
  import {onMount} from "svelte";
  //   import type {Step} from "../interfaces/Step";

  export let steps = [];
  export let currentStepNumber;

  let stepperSteps = [];
  //   let stepperSteps: Step[] = [];
  let stepsState;

  stepsStateRef.subscribe(value => (stepsState = value));

  onMount(async () => {
    const stepsState = steps.map((step, index) => {
      // const stepsState: Step[] = steps.map((step, index) => {
      const stepObj = {
        description: step,
        completed: false,
        highlighted: index === 0 ? true : false,
        selected: index === 0 ? true : false,
      };
      return stepObj;
    });
    stepsStateRef.set(stepsState);
    const currentSteps = updateStep(currentStepNumber - 1, stepsState);
    stepperSteps = currentSteps;
  });

  $: onChange(currentStepNumber);

  /**
   * updateStep
   *@param {[]} stepNumber - currentStepNumber -1
   */
  function onChange() {
    const currentSteps = updateStep(currentStepNumber - 1, stepsState);
    stepperSteps = currentSteps;
  }

  /**
   * updateStep
   *@param {number} stepNumber - currentStepNumber -1
   *@param {number} steps number
   * @return {Obj}
   */
  function updateStep(stepNumber, steps) {
    const newSteps = [...steps];
    let stepCounter = 0;
    while (stepCounter < newSteps.length) {
      // current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future steps
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }
    return newSteps;
  }
</script>

<div class="uc-flex uc-justify-between uc-items-center">
  {#each stepperSteps as step, index}
    <div
      class={index !== stepperSteps.length - 1
        ? "uc-w-full uc-flex uc-items-center"
        : "uc-flex uc-items-center"}
    >
      <div class="uc-relative uc-flex uc-flex-col uc-items-center">
        <div
          class={`uc-relative uc-rounded-full uc-transition uc-duration-500 uc-ease-in-out uc-border-2 after:uc-absolute after:uc--inset-1.5 after:uc-rounded-full after:uc-border after:uc-border-solid uc-h-size-tiny-x-spacing uc-w-size-tiny-x-spacing uc-flex uc-items-center uc-justify-center  ${
            step.selected
              ? "uc-bg-color-secondary uc-border-color-secondary after:uc-border-color-secondary uc-text-white uc-font-bold"
              : "uc-bg-color-columbia-blue uc-border-color-columbia-blue after:uc-border-color-columbia-blue"
          }`}
        >
          {#if step.completed}
            <span class="uc-text-white uc-font-bold uc-text-xs"
              ><svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.90774 0.127657C7.05419 0.274104 7.05419 0.511541 6.90774 0.657987L2.78274 4.78299C2.6363 4.92943 2.39886 4.92943 2.25241 4.78299L0.377413 2.90799C0.230966 2.76154 0.230966 2.5241 0.377413 2.37766C0.52386 2.23121 0.761297 2.23121 0.907743 2.37766L2.51758 3.98749L6.37741 0.127657C6.52386 -0.0187894 6.7613 -0.0187894 6.90774 0.127657Z"
                  fill="white"
                />
              </svg>
            </span>
          {/if}
        </div>
      </div>
      <div
        class={`uc-flex-auto uc-border-t-2 uc-transition uc-duration-500 uc-ease-in-out ${
          index + 1 < currentStepNumber
            ? "uc-border-color-secondary"
            : "uc-border-color-columbia-blue"
        } `}
      />
    </div>
  {/each}
</div>
