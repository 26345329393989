<script lang="ts">
  import * as yup from "yup";
  import {onMount} from "svelte";
  import {fade} from "svelte/transition";
  import {userDetails} from "../stores/stores";
  import Input from "../components/Input.svelte";
  import Button from "../components/Button.svelte";
  import {sendDocuments} from "../api/covidcare";
  import Data from "../utils/links";

  let isLoading = false;

  let values = {
    email: "",
  };
  let errors = {
    email: "",
  };

  const result = {
    success: false,
    message: "",
  };

  const schema = yup.object().shape({
    email: yup.string().required("Enter Email").email("Email is invalid"),
  });

  $: invalid = async (name: string) => {
    try {
      await yup.reach(schema, name).validate(values[name]);
      errors[name] = "";
    } catch (err) {
      errors[name] = err.message;
    }
  };

  const handleChange = e => {
    values[e.target.name] = e.target.value;
    invalid(e.target.name);
  };

  const handleSubmit = async () => {
    try {
      isLoading = true;
      await schema.validate(values, {abortEarly: false});
      errors = {
        email: "",
      };
      userDetails.update(user => {
        const newUser = user;
        newUser.email = values.email;
        return newUser;
      });

      try {
        const res = await sendDocuments(values.email);

        if (res.status === 200) {
          result.success = true;
          result.message =
            "Your Documents have been sent to the registered email";
        } else {
          result.success = false;
          result.message = res.data.message;
        }

        setTimeout(() => {
          result.message = "";
        }, 5000);
      } catch (e) {
        result.success = false;
        result.message = "Network Error";

        setTimeout(() => {
          result.message = "";
        }, 5000);
      }
    } catch (err) {
      errors = err.inner.reduce((acc, err) => {
        return {...acc, [err.path]: err.message};
      }, {});
    } finally {
      isLoading = false;
    }
  };

  onMount(async () => {
    userDetails.subscribe(value => {
      values = value;
    });
  });
</script>

<div class="uc-p-size-default-x-spacing">
  <h1
    class="uc-text-body uc-text-center uc-font-extrabold uc-text-color-text-primary uc-mb-5"
  >
    Already have cover?
  </h1>
  <div class="uc-w-full uc-mb-5">
    <p
      class="uc-text-label uc-w-4/5 uc-mx-auto uc-text-center uc-font-light uc-text-color-text-secondary"
    >
      Enter email against which cover is purchased
    </p>
  </div>
  {#if result.message}
    <div
      out:fade
      class={`uc-w-full uc-inline-flex ${
        result.success
          ? "uc-bg-color-antiflash-white uc-p-4 uc-items-start"
          : "uc-bg-color-lavender-blush uc-p-size-tiny-x-spacing uc-pr-8 uc-items-center"
      } uc-rounded-md `}
    >
      <div class="uc-mr-2">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.9987 14.0026C11.3124 14.0026 13.9987 11.3163 13.9987 8.0026C13.9987 4.6889 11.3124 2.00261 7.9987 2.00261C4.68499 2.00261 1.9987 4.6889 1.9987 8.0026C1.9987 11.3163 4.68499 14.0026 7.9987 14.0026ZM15.332 8.0026C15.332 12.0527 12.0488 15.3359 7.9987 15.3359C3.94861 15.3359 0.665365 12.0527 0.665365 8.0026C0.665365 3.95252 3.94861 0.669271 7.9987 0.669271C12.0488 0.669271 15.332 3.95252 15.332 8.0026Z"
            fill={`${result.success ? "#7988A9" : "#FF515D"}`}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.9987 8.66927C7.63051 8.66927 7.33203 8.37079 7.33203 8.0026V5.33594C7.33203 4.96775 7.63051 4.66927 7.9987 4.66927C8.36689 4.66927 8.66537 4.96775 8.66537 5.33594V8.0026C8.66537 8.37079 8.36689 8.66927 7.9987 8.66927Z"
            fill={`${result.success ? "#7988A9" : "#FF515D"}`}
          />
          <path
            d="M7.33203 10.6693C7.33203 10.3011 7.63051 10.0026 7.9987 10.0026C8.36689 10.0026 8.66537 10.3011 8.66537 10.6693C8.66537 11.0375 8.36689 11.3359 7.9987 11.3359C7.63051 11.3359 7.33203 11.0375 7.33203 10.6693Z"
            fill={`${result.success ? "#7988A9" : "#FF515D"}`}
          />
        </svg>
      </div>
      <p
        class={`${
          result.success
            ? "uc-text-color-text-secondary"
            : "uc-text-color-text-danger"
        } uc-text-footer uc-font-medium`}
      >
        {result.message}
      </p>
    </div>
  {/if}
  <form class="uc-mt-5 uc-mb-10" on:submit|preventDefault={handleSubmit}>
    <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
      <Input
        styleClass="uc-w-full uc-bg-white"
        id="email"
        type="text"
        name="email"
        label="email"
        placeholder="email"
        {handleChange}
        value={values.email}
        errored={errors.email}
        errorMessage={errors.email}
      />
    </div>
    <Button {isLoading} type="submit">Get Policy Documents</Button>
  </form>
  <div class="uc-flex uc-justify-between uc-items-center">
    <a
      href={Data.links["Cancel Policy"].href}
      target="_blank"
      class="uc-text-footer uc-font-medium uc-underline uc-text-color-text-primary"
      >Cancel Policy?</a
    >
    <a
      href={Data.links["Make Claim"].href}
      target="_blank"
      class="uc-text-footer uc-font-medium uc-underline uc-text-color-text-primary"
      >Make a claim</a
    >
  </div>
</div>
