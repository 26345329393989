<script lang="ts">
  import * as yup from "yup";
  import {onMount} from "svelte";
  import Input from "../components/Input.svelte";
  import {formatPhone} from "../utils/formatter";
  import {registerUser} from "../api/covidcare";
  import Button from "../components/Button.svelte";
  import PhoneInput from "../components/PhoneInput.svelte";
  import {
    partnerId,
    showModal,
    userDetails,
    identityId,
  } from "../stores/stores";

  let values = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  };
  let errors = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  };

  let isLoading = false;

  const schema = yup.object().shape({
    firstName: yup.string().required("Enter First Name"),
    lastName: yup.string().required("Enter Last Name"),
    mobileNumber: yup
      .string()
      .required("Enter Phone Number")
      .matches(/^4/i, "Enter your phone number starting with a 4")
      .min(11, "Please Ensure Phone Number has 9 digits")
      .max(11, "Please Ensure Phone Number has 9 digits"),
    email: yup.string().required("Enter Email").email("Email is invalid"),
  });

  $: invalid = async (name: string) => {
    try {
      await yup.reach(schema, name).validate(values[name]);
      errors[name] = "";
    } catch (err) {
      errors[name] = err.message;
    }
  };

  const handleChange = e => {
    values[e.target.name] = e.target.value;
    invalid(e.target.name);
  };

  const handlePhoneChange = e => {
    const value = e.target.value;

    const finalNumber = formatPhone(value);

    values.mobileNumber = finalNumber;
    invalid(e.target.name);
  };

  const handleSubmit = async () => {
    isLoading = true;
    try {
      await schema.validate(values, {abortEarly: false});
      errors = {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
      };
      const {firstName, lastName, mobileNumber, email} = values;
      userDetails.update(user => {
        return {...user, firstName, lastName, mobileNumber, email};
      });
      const res = await registerUser(values);
      identityId.set(res.data.id);
      await window.analytics.identify($identityId, {
        phone: values.mobileNumber,
        name: `${values.firstName} ${values.lastName}`,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        recent_partner_id: $partnerId,
        sku: "Covid Insurance",
        Status: "CHECK ELIGIBILITY",
        ...($partnerId !== "upcover" && {[`partner_${$partnerId}`]: "yes"}),
      });
      showModal.set(false);
    } catch (err) {
      errors = err.inner.reduce((acc, err) => {
        return {...acc, [err.path]: err.message};
      }, {});
    } finally {
      isLoading = false;
    }
  };

  onMount(async () => {
    userDetails.subscribe(({firstName, lastName, mobileNumber, email}) => {
      values = {firstName, lastName, mobileNumber, email};
    });
  });
</script>

<div class="uc-p-size-default-x-spacing">
  <h1
    class="uc-text-body uc-text-center uc-font-extrabold uc-text-color-text-primary uc-mb-3"
  >
    Almost there!
  </h1>
  <p
    class="uc-text-label uc-text-center uc-font-light uc-text-color-text-secondary uc-mb-3"
  >
    Share your contact details to see eligibility
  </p>
  <form on:submit|preventDefault={handleSubmit}>
    <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
      <Input
        styleClass="uc-basis-1/2"
        id="firstName"
        type="text"
        name="firstName"
        label="first name"
        placeholder="first name"
        value={values.firstName}
        {handleChange}
        errored={errors.firstName}
        errorMessage={errors.firstName}
      />
      <Input
        styleClass="uc-basis-1/2"
        id="lastName"
        type="text"
        name="lastName"
        label="last name"
        placeholder="last name"
        value={values.lastName}
        {handleChange}
        errored={errors.lastName}
        errorMessage={errors.lastName}
      />
    </div>
    <div class="uc-w-full uc-mb-5 uc-flex uc-gap-x-3.5">
      <PhoneInput
        styleClass="uc-w-full uc-bg-white"
        id="mobileNumber"
        type="text"
        name="mobileNumber"
        label="phone number"
        placeholder="phone number"
        value={values.mobileNumber}
        {handlePhoneChange}
        errored={errors.mobileNumber}
        errorMessage={errors.mobileNumber}
      />
    </div>
    <div class="uc-w-full uc-mb-size-default-x-spacing uc-flex uc-gap-x-3.5">
      <Input
        styleClass="uc-w-full uc-bg-white"
        id="email"
        type="text"
        name="email"
        label="email"
        placeholder="email"
        {handleChange}
        value={values.email}
        errored={errors.email}
        errorMessage={errors.email}
      />
    </div>
    <Button {isLoading} type="submit">Check Eligibility</Button>
  </form>
</div>
