import Api from "../services/api";

// register user
export const registerUser = async body => {
  try {
    const response = await Api.post("/register-user", body);
    return response;
  } catch (error) {
    return error.response;
  }
};

// has policy
export const sendDocuments = async email => {
  try {
    const response = await Api.get(
      `/sendDocuments?email=${encodeURIComponent(email)}`,
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

// paymant
export const payment = async body => {
  try {
    const response = await Api.post("/payment", body);
    return response;
  } catch (error) {
    return error.response;
  }
};
